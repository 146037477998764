<template>
  <div style="direction: ltr !important">
    <div class="text-center my-5" v-show="!Ready">
      <v-img src="/loading.png" height="100" contain class="Warning" />
    </div>
    <v-card width="100%" class="text-center pa-5" flat style="overflow: hidden" v-if="Ready">
      <v-btn block color="info" @click="[GeneralClasses.ReloadPage()]" class="mb-3"><v-icon>mdi-close-circle-outline</v-icon></v-btn>
      <div class="DashedBorders pa-2 mb-2">
        <v-row>
          <v-col cols="4">
            <div class="font-weight-bold success--text">Gegeben</div>
            {{ Number(Order.Item.gived_money).toFixed(2) }}€
          </v-col>
          <v-col cols="4">
            <div class="font-weight-bold info--text">Summe</div>
            {{ (Number(Order.Item.brutto) + Number(Order.Item.pledge)).toFixed(2) }}
            €
          </v-col>
          <v-col cols="4">
            <div class="font-weight-bold error--text">Rückgeld</div>
            {{ Number(Order.Item.returned_money).toFixed(2) }}€
          </v-col>
        </v-row>
      </div>
      <div id="PrintArea" class="PrintArea" style="color: black !important; background-color: white !important">
        <table width="100%">
          <tr>
            <td style="text-align: center">
              <h4 style="margin-bottom: 0.25cm"> Das ist keine Rechnung </h4>
           <h3 v-if="Order.Item.table_nr > 0">
            Table_NR :  {{ Order.Item.table_nr }}
              </h3>
              <h3 v-if="Order.Item.table_nr <= 0">  outside restaurant </h3>

            <img :src="GeneralClasses.LiekasseDomain() + '/' + user.PublicSetting.Photo" :alt="user.PublicSetting.Name" style="margin-bottom: 0.25cm; width: 3cm" />
              <div style="font-weight: bold">
                {{ user.PublicSetting.Name }}
              </div>
              <div>{{ user.PublicSetting.Address }}, {{ user.PublicSetting.Zip }}</div>
              <div>Tel : {{ user.PublicSetting.Tel }}</div>
              <div style="margin-bottom: 0.25cm">
                {{ user.PublicSetting.Opentime }}
              </div>
              <div v-if="user.PublicSetting.Type == 2 && Order.Item.cart_nr != -1" style="font-weight: bold; font-size: 25pt">
                {{ Order.Item.cart_nr }}
              </div>
              <div v-if="Order.Item.type == 1 || Order.Item.type == 2" style="font-weight: bold; font-size: 11pt">Kassenbon NR{{ Order.Item.receipt_nr }}</div>
              <div v-if="Order.Item.type == 2" style="font-weight: bold; font-size: 11pt">Erstattung Kassenbon NR{{ Order.Item.returned_receipt_nr }}</div>
              <div v-if="Order.Item.type == 3" style="font-weight: bold; font-size: 11pt">Bonabbruch Kassenbon NR{{ Order.Item.bon_abbr }}</div>
              <div style="font-size: 11pt">
                {{ $Moment(String(Order.Item.created_at)).format("DD.MM.YYYY, HH:mm:ss") }}
              </div>
            </td>
          </tr>
        </table>

        <div v-for="(Item, index) in Order.Item.order_detals" :key="index">
          <hr style="margin-top: 0.1cm; margin-bottom: 0.1cm; opacity: 0.2; border-top: 1px dashed black" />
          <table width="100%" style="font-family: 'Arial' !important">
            <tr>
              <td style="text-align: left; width: 0.1cm; font-size: 10pt; font-weight: bold; padding-bottom: 3px">{{ Number(index + 1) }}-</td>
              <td style="text-align: left; font-size: 10pt">
                <div style="font-weight: bold">
                  {{ Item.product_name_de }}- {{ Item.QTY }} x {{ Number(Item.offer_price) }}
                  {{ Item.unit == 2 ? Number(Item.offer_price) && Dictionary.GetTheRightWeight(Item.unit, "DE") : "" }}
                </div>
              </td>

              <td style="text-align: right; width: 2cm; font-size: 10pt; font-weight: bold">
                {{ Number(Item.total_price + Item.pledge).toFixed(2) }}
                €
              </td>
            </tr>
          </table>

          <table width="100%">
            <tr align="left">
              <td style="font-weight: 100; font-size: 10pt" v-show="Item.tags.length > 0">
                <div style="margin-left: 8px; font-size: 9pt" v-for="tag in Item.tags" :key="tag.id">{{ tag.tag_de + " - " + tag.price }} €</div>
              </td>
            </tr>
          </table>
          <table width="100%">
            <tr align="left">
              <td style="font-weight: 100; font-size: 10pt" v-if="Item.notes != ''">
                <div style="display: inline-block; margin-left: 7px; font-weight: lighter; font-size: 9pt">
                  {{ Item.notes + " - " }}
                </div>
              </td>
            </tr>
          </table>
        </div>
        <hr style="margin-top: 0.15cm; margin-bottom: 0.5cm; opacity: 0.3; border-top: 1px dashed black" />
        <div>
          <table width="100%">
            <tr>
              <td style="font-weight: bold; text-align: left; width: 50%; font-size: 12pt">Summe</td>
              <td style="font-weight: bold; text-align: right; width: 50%; font-size: 12pt">
                {{ (Number(Order.Item.brutto) + Number(Order.Item.pledge)).toFixed(2) }}
                €
              </td>
            </tr>
          </table>
        </div>
        <hr style="margin-top: 0.15cm; margin-bottom: 0.5cm; opacity: 0.3; border-top: 1px dashed black" />
        <div>
          <table width="100%">
            <tr>
              <td style="font-weight: bold; text-align: left; font-size: 10pt">Anmerkungen :</td>
              <td style="font-weight: bold; text-align: right; font-size: 8pt">
                {{ Order.Item.notes }}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
// JS Scripts
import GeneralClasses from "../../../SubClasses/GeneralClasses.js";
import Dictionary from "../../../SubClasses/Dictionary.js";

export default {
  name: "PrintsOrder",
  props: ["user", "order", "type"],
  data() {
    return {
      GeneralClasses: GeneralClasses,
      Dictionary: Dictionary,
      LSSettings: JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())),

      InstallValues: {
        LiekasseAT: window.atob(JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())).ATs.LiekasseAT),
      },

      Ready: false,
      Order: {
        Item: [],
      },

      BarcodeOptions: {
        format: "CODE128",
        height: 50,
        width: 6,
        displayValue: true,
        default: true,
        textPosition: "bottom",
        textAlign: "center",
      },
    };
  },
  methods: {
    GetOrder() {
      var API = window.atob(this.GeneralClasses.APIPOSSystem22());
      this.$http
        .get(this.GeneralClasses.LiekasseDomain() + API + this.order, this.GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT))
        .then((Result) => {
          if (Result != null) {
            this.Order.Item = Result.data.data;
            this.Ready = true;
            console.log(this.Order.Item);

            setTimeout(() => {
              this.GeneralClasses.Print(this.user, "Invoice", "PrintArea");
            }, 1000);
          }
        })
        .catch((Error) => console.log("Error 0 : ", Error));
    },
  },
  watch: {
    type: function (Value) {
      this.type = Value;
    },
    order: function (Value) {
      this.order = Value;
    },
    user: function (Value) {
      this.user = Value;
    },
  },
  mounted() {
    this.GetOrder();
    // console.log(this.user.PrivateSetting.TSEInfos);
  },
};
</script>
<style scoped>
.DashedBorders {
  border-collapse: collapse;
  border: 1px dashed black;
}
</style>
