// https://github.com/avil13/vue-sweetalert2
import "sweetalert2/dist/sweetalert2.min.css";
import Swal from "sweetalert2";
import Dictionary from "../SubClasses/Dictionary.js";
import axios from "axios";
export default {
  LiekasseDomain: function() {
    return window.location.hostname.includes("localhost")
      ? "http://127.0.0.1:8000"
      : "";
  },
  LiefoodDomain: function() {
    return "https://liefood.robokids.academy";
  },
  // ************************************************************************
  // Liekasse
  // ************************************************************************
  // /api/install
  APIInstall: function() {
    return "L2FwaS9pbnN0YWxs";
  },
  // /api/box-install
  APIInstallBox: function() {
    return "L2FwaS9ib3gtaW5zdGFsbCA=";
  },

  // /api/logs/
  API0: function() {
    return "L2FwaS9jYXRlZ29yeS8=";
  },
  // /api/category/
  API1: function() {
    return "L2FwaS9jYXRlZ29yeS8=";
  },
  // /api/product
  API2: function() {
    return "L2FwaS9wcm9kdWN0Lw==";
  },
  // /api/product-by-barcode/
  API3: function() {
    return "L2FwaS9wcm9kdWN0LWJ5LWJhcmNvZGUv";
  },
  // /api/product-by-category/
  API4: function() {
    return "L2FwaS9wcm9kdWN0LWJ5LWNhdGVnb3J5Lw==";
  },
  // /api/favorite-product/
  API5: function() {
    return "L2FwaS9mYXZvcml0ZS1wcm9kdWN0Lw==";
  },
  // /api/product-by-search/
  API6: function() {
    return "L2FwaS9wcm9kdWN0LWJ5LXNlYXJjaC8=";
  },
  // /api/order/
  API7: function() {
    return "L2FwaS9vcmRlci8=";
  },
  // /api/order-sum/
  API7Sum: function() {
    return "L2FwaS9vcmRlci1zdW0vIA==";
  },
  // /api/top-products/
  API7TopProducts: function() {
    return "L2FwaS90b3AtcHJvZHVjdHM=";
  },
  // /api/order-id/
  API7ByID: function() {
    return "L2FwaS9vcmRlci1pZC8=";
  },

  // /api/order-invoice/
  API7ByNr1: function() {
    return "L2FwaS9vcmRlci1pbnZvaWNlLw==";
  },
  // /api/order-returned/
  API7ByNr2: function() {
    return "L2FwaS9vcmRlci1yZXR1cm5lZC8=";
  },
  // /api/order-bon_abbr/
  API7ByNr3: function() {
    return "L2FwaS9vcmRlci1ib25fYWJici8=";
  },

  // /api/setting
  API8: function() {
    return "L2FwaS9zZXR0aW5nICAg";
  },
  // /api/update-private-setting
  API8UPrivate: function() {
    return "L2FwaS91cGRhdGUtcHJpdmF0ZS1zZXR0aW5n";
  },
  // /api/update-public-setting
  API8UPublic: function() {
    return "L2FwaS91cGRhdGUtcHVibGljLXNldHRpbmc=";
  },
  // /api/show-private-setting
  API8SPrivate: function() {
    return "L2FwaS9zaG93LXByaXZhdGUtc2V0dGluZw==";
  },
  // /api/show-public-setting
  API8SPublic: function() {
    return "L2FwaS9zaG93LXB1YmxpYy1zZXR0aW5n";
  },
  // /api/store-images
  API9: function() {
    return "L2FwaS9zdG9yZS1pbWFnZXMg";
  },

  // /api/register/
  API10: function() {
    return "L2FwaS9yZWdpc3Rlci8=";
  },
  // /api/create-super-admin
  API10S: function() {
    return "L2FwaS9jcmVhdGUtc3VwZXItYWRtaW4=";
  },

  // /api/login/
  API11: function() {
    return "L2FwaS9sb2dpbi8=";
  },

  // /api/logout/
  API111: function() {
    return "L2FwaS9sb2dvdXQ=";
  },
  // /api/logoutfromall/
  API112: function() {
    return "L2FwaS9sb2dvdXQtYWxs";
  },

  // /api/user-info/
  API12: function() {
    return "L2FwaS91c2VyLWluZm8vICA=";
  },
  // /api/box-status/
  API13: function() {
    return "L2FwaS9ib3gtc3RhdHVzLw==";
  },
  // /api/box-record/
  API14: function() {
    return "L2FwaS9ib3gtcmVjb3JkLw==";
  },
  // /api/box-difference/
  API15: function() {
    return "L2FwaS9ib3gtZGlmZmVyZW5jZS8=";
  },
  // /api/reset-box/
  API16: function() {
    return "L2FwaS9yZXNldC1ib3gv";
  },
  // /api/fill-box/
  API17: function() {
    return "L2FwaS9maWxsLWJveC8=";
  },
  // /api/ausgabe/
  API17Ausgabe: function() {
    return "L2FwaS9hdXNnYWJlLw==";
  },
  // /api/cart/
  API18: function() {
    return "L2FwaS9jYXJ0Lw==";
  },
  // /api/reset-cart
  API18Reset: function() {
    return "L2FwaS9yZXNldC1jYXJ0";
  },
  // /api/box-sum/
  API19: function() {
    return "L2FwaS9ib3gtc3VtLw==";
  },
  // /api/printer/
  API20: function() {
    return "L2FwaS9wcmludGVyLw==";
  },
  // /api/user-all
  API21: function() {
    return "L2FwaS91c2VyLWFsbA==";
  },
  // /api/store-z-report
  API22: function() {
    return "L2FwaS9zdG9yZXpyZXBvcnQ=";
  },
  // /api/tag
  API23: function() {
    return "L2FwaS90YWcv";
  },
  // /api/tag/ !!delete
  API24: function() {
    return "L2FwaS90YWcv";
  },
  // /api/tags_by_product
  API25: function() {
    return "L2FwaS90YWdzX2J5X3Byb2R1Y3Q=";
  },
  // /api/payment_Sepa
  API26: function() {
    return "L2FwaS9wYXltZW50X1NlcGE=";
  },
  // /api/get_user_data
  API27: function() {
    return "L2FwaS9nZXRfdXNlcl9kYXRh";
  },
  // /api/update_setting
  API28: function() {
    return "L2FwaS91cGRhdGVfc2V0dGluZw==";
  },
  // /api/user-update-setting
  API12UpdateSetting: function() {
    return "L2FwaS91c2VyLXVwZGF0ZS1zZXR0aW5n";
  },
  // /api/totalUserPayment
  API29: function() {
    return "L2FwaS90b3RhbFVzZXJQYXltZW50";
  },

  // /api/respotMonth
  API30: function() {
    return "L2FwaS9yZXNwb3RNb250aA==";
  },

  // /api/order_by_cart_nr
  APIPOSSystem0: function() {
    return "L2FwaS9vcmRlcl9ieV9jYXJ0X25y";
  },
  // /api/add_product
  APIPOSSystem1: function() {
    return "L2FwaS9hZGRfcHJvZHVjdA==";
  },
  // /api/add_product_barcode
  APIPOSSystem1Barcode: function() {
    return "L2FwaS9hZGRfcHJvZHVjdF9iYXJjb2Rl";
  },
  // /api/add_seprated
  APIPOSSystem2: function() {
    return "L2FwaS9hZGRfc2VwcmF0ZWQ=";
  },
  // /api/delete_product
  APIPOSSystem3: function() {
    return "L2FwaS9kZWxldGVfcHJvZHVjdA==";
  },
  // /api/update_table_nr
  APIPOSSystem4: function() {
    return "L2FwaS91cGRhdGVfdGFibGVfbnI=";
  },
  // /api/update_price
  APIPOSSystem5: function() {
    return "L2FwaS91cGRhdGVfcHJpY2U=";
  },
  // /api/update_product_qty
  APIPOSSystem6: function() {
    return "L2FwaS91cGRhdGVfcHJvZHVjdF9xdHk=";
  },
  // /api/gived_money
  APIPOSSystem7: function() {
    return "L2FwaS9naXZlZF9tb25leQ==";
  },
  // /api/pay_cash
  APIPOSSystem8: function() {
    return "L2FwaS9wYXlfY2FzaA==";
  },
  // /api/pay_ec
  APIPOSSystem9: function() {
    return "L2FwaS9wYXlfZWM=";
  },
  // /api/order_place
  APIPOSSystem10: function() {
    return "L2FwaS9vcmRlcl9wbGFjZQ==";
  },
  // /api/save_peending_order
  APIPOSSystem11: function() {
    return "L2FwaS9zYXZlX3BlZW5kaW5nX29yZGVy";
  },
  // /api/peending_orders
  APIPOSSystem12: function() {
    return "L2FwaS9wZWVuZGluZ19vcmRlcnM=";
  },
  // /api/tags_by_product
  APIPOSSystem13: function() {
    return "L2FwaS90YWdzX2J5X3Byb2R1Y3Q=";
  },
  // /api/add_seprated
  APIPOSSystem14: function() {
    return "L2FwaS9zZXByYXRlZF9wYXk=";
  },
  // /api/add_seprated
  APIPOSSystem15: function() {
    return "L2FwaS9zZXByYXRlZF9wYXk=";
  },
  // /api/delete-order/
  APIPOSSystem16: function() {
    return "L2FwaS9kZWxldGUtb3JkZXIv";
  },
  // /api/update-notes/
  APIPOSSystem17: function() {
    return "L2FwaS91cGRhdGUtbm90ZXMv";
  },
  // /api/check_order_details
  APIPOSSystem18: function() {
    return "L2FwaS9jaGVja19vcmRlcl9kZXRhaWxz";
  },
  // /api/create_seprated_order
  APIPOSSystem19: function() {
    return "L2FwaS9jcmVhdGVfc2VwcmF0ZWRfb3JkZXI=";
  },
  // /api/cancle_order
  APIPOSSystem20: function() {
    return "L2FwaS9jYW5jbGVfb3JkZXI=";
  },
  // /api/cancle_seprated_order
  APIPOSSystem21: function() {
    return "L2FwaS9jYW5jbGVfc2VwcmF0ZWRfb3JkZXI=";
  },
  // /api/show-order/
  APIPOSSystem22: function() {
    return "L2FwaS9zaG93LW9yZGVyLw==";
  },
  // /api/product_by_barcode
  APIPOSSystem23: function() {
    return "L2FwaS9wcm9kdWN0X2J5X2JhcmNvZGU=";
  },
  // /api/order_details_notes
  APIPOSSystem24: function() {
    return "L2FwaS9vcmRlcl9kZXRhaWxzX25vdGVz";
  },
  // /api/update_priceDB
  APIPOSSystem25: function() {
    return "L2FwaS91cGRhdGVfcHJpY2VEQg==";
  },
  // /api/add-tag
  APIPOSSystem26: function() {
    return "L2FwaS9hZGQtdGFn";
  },

  // /api/destroy_tag
  APIPOSSystem27: function() {
    return "L2FwaS9kZWxldGUtdGFn";
  },
  // /api/delete_peending_orders
  APIPOSSystem28: function() {
    return "L2FwaS9kZWxldGVfcGVlbmRpbmdfb3JkZXJzLw==";
  },

  // Liefood API

  // /api/postal_codes_by_market/
  APILiefood1: function() {
    return "L2FwaS9wb3N0YWxfY29kZXNfYnlfbWFya2V0Lw==";
  },
  // /api/add_postal_code
  APILiefood2: function() {
    return "L2FwaS9hZGRfcG9zdGFsX2NvZGU=";
  },
  // /api/update_postal_code
  APILiefood3: function() {
    return "L2FwaS91cGRhdGVfcG9zdGFsX2NvZGU=";
  },
  // /api/general/
  APIAllinOne: function() {
    return "L2FwaS9nZW5lcmFsLw==";
  },

  // /api/box-report
  APIBoxReport: function() {
    return "L2FwaS9ib3gtcmVwb3J0";
  },

  // ************************************************************************

  // LS
  LSSettings: function() {
    return "7F3za4ePdiNQPblWq5pU";
  },

  GetHeaderValue: function(Authorization, PageCount) {
    if (PageCount == null) {
      return {
        headers: {
          "Content-Type": "application/json",
          Authorization: Authorization != null ? "Bearer " + Authorization : "",
        },
      };
    } else {
      return {
        headers: {
          "Content-Type": "application/json",
          Authorization: Authorization != null ? "Bearer " + Authorization : "",
          paginate: PageCount,
        },
      };
    }
  },
  GetHeaderValueWithGroup: function(Authorization, PageCount, GroupValue) {
    if (PageCount == null) {
      return {
        headers: {
          "Content-Type": "application/json",
          Authorization: Authorization != null ? "Bearer " + Authorization : "",
        },
      };
    } else {
      return {
        headers: {
          "Content-Type": "application/json",
          Authorization: Authorization != null ? "Bearer " + Authorization : "",
          paginate: PageCount,
          group: GroupValue,
        },
      };
    }
  },
  PrintSize: function(Size) {
    if (Size == "Product") {
      return '"height:12mm; width:90mm;"';
    } else if (Size == "Invoice") {
      return "height:50mm; width:90mm;";
    } else if (Size == "PrinterAsBox") {
      return "height:0mm; width:0mm;";
    } else if (Size == "PrintsBonApp") {
      return "height:0mm; width:0mm;";
    }
  },
  Print(User, Type, PrintBody) {
    var PrintSetting = this.PrintSize(Type);
    var PrintWindow = null;
    if (User.PrivateSetting.Print.System == 0) {
      PrintWindow = window.open(
        "",
        "_blank",
        "toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,left=0,top=0, width=1, height=1, visible=none"
      );
    } else {
      PrintWindow = window.open(
        "",
        "_blank",
        "toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,left=0,top=50, width=" +
          Number(window.innerWidth) +
          ", height=500, visible=none"
      );
    }
    PrintWindow.document.write(
      "<html><head><style> .to-print{" +
        PrintSetting +
        " margin:auto; } </style></head><body>"
    );
    PrintWindow.document.write(document.getElementById(PrintBody).innerHTML);
    PrintWindow.document.write("</body></html>");
    PrintWindow.document.close();
    PrintWindow.focus();
    PrintWindow.print();

    if (User.PrivateSetting.Print.System == 0) {
      setTimeout(() => {
        PrintWindow.close();
      }, 200);
    }
  },
  CheckIfSmaller(Value1, Value2) {
    if (Value1 < Value2) return true;
    return Dictionary.GetString("entervalidformat");
  },
  Check(Role) {
    var Array = JSON.parse(
      window.atob(
        JSON.parse(localStorage.getItem(this.LSSettings())).UserInfo.Sections
      )
    );
    if (Array.includes(Role)) {
      return true;
    } else {
      return false;
    }
  },

  WeightBarcode: function() {
    return "0100000";
  },
  WeightBarcode2: function() {
    return "6666666";
  },

  UpdateCartCopy: function(Item, AddOne) {
    var LSSettings = JSON.parse(localStorage.getItem(this.LSSettings()));
    Item.Index = LSSettings.Checkout.Cart.Items.length + 1;
    if (
      Item.barcode == this.WeightBarcode() ||
      Item.barcode == this.WeightBarcode2()
    ) {
      LSSettings.Checkout.Cart.Items.push(Item);
      localStorage.setItem(this.LSSettings(), JSON.stringify(LSSettings));
      return false;
    } else {
      var CartCheck = LSSettings.Checkout.Cart.Items.find((Element) => {
        return Element.id === Item.id;
      });
      if (!CartCheck) {
        Item.NewQTY = 1;
        LSSettings.Checkout.Cart.Items.push(Item);
        localStorage.setItem(this.LSSettings(), JSON.stringify(LSSettings));
        return true;
      } else {
        LSSettings.Checkout.Cart.Items.find((Element) => {
          if (Element.id === Item.id) {
            if (AddOne) {
              Element.NewQTY = Element.NewQTY + 1;
            } else {
              Element.NewQTY = Item.NewQTY || 1;
            }
            Element.offer_price = Item.offer_price;
          }
        });
        localStorage.setItem(this.LSSettings(), JSON.stringify(LSSettings));
        return false;
      }
    }
  },
  AddNewItem: function(Item, LSSettings, Separated) {
    Item.Index = LSSettings.Checkout.Cart.Items.length + 1;
    Item.NewQTY = 1;
    if (Separated) {
      Item.Separated = true;
    } else {
      Item.Separated = false;
    }
    LSSettings.Checkout.Cart.Items.push(Item);
  },
  AddToCart: function(Item, Separated) {
    var LSSettings = JSON.parse(localStorage.getItem(this.LSSettings()));
    if (Separated) {
      this.AddNewItem(Item, LSSettings, true);
    } else {
      if (LSSettings.Checkout.Cart.Items.length != 0) {
        var FoundElement = LSSettings.Checkout.Cart.Items.find(
          (Element) => Element.id === Item.id && Element.Separated === false
        );
        if (FoundElement) {
          FoundElement.NewQTY += 1;
        } else {
          this.AddNewItem(Item, LSSettings);
        }
      } else {
        this.AddNewItem(Item, LSSettings);
      }
    }
    localStorage.setItem(this.LSSettings(), JSON.stringify(LSSettings));
  },
  UpdateCart: function(Item, QTY, Value) {
    var LSSettings = JSON.parse(localStorage.getItem(this.LSSettings()));
    var FoundElement = LSSettings.Checkout.Cart.Items.find(
      (Element) => Element.Index === Item.Index
    );
    if (FoundElement) {
      console.log(FoundElement);
      if (QTY) {
        FoundElement.NewQTY = Value;
      } else {
        FoundElement.offer_price = Value;
      }
    }
    localStorage.setItem(this.LSSettings(), JSON.stringify(LSSettings));
  },
  ClearFromCart: function(Item) {
    var LSSettings = JSON.parse(localStorage.getItem(this.LSSettings()));
    if (Item == null) {
      LSSettings.Checkout.Cart.ID = 0;
      LSSettings.Checkout.Cart.TableNR = 0;
      LSSettings.Checkout.Cart.Items = [];
      LSSettings.Checkout.Cart.DisItems = [];
      localStorage.setItem(this.LSSettings(), JSON.stringify(LSSettings));
    } else {
      for (
        var Index = 0;
        Index < LSSettings.Checkout.Cart.Items.length;
        Index++
      ) {
        if (LSSettings.Checkout.Cart.Items[Index].Index === Item.Index) {
          try {
            LSSettings.Checkout.Cart.DisItems.push(
              LSSettings.Checkout.Cart.Items[Index]
            );
          } finally {
            LSSettings.Checkout.Cart.Items.splice(Index, 1);
          }
          break;
        }
      }
      localStorage.setItem(this.LSSettings(), JSON.stringify(LSSettings));
    }
  },
  ConfirmAlert: function(title) {
    return Swal.fire({
      position: "center",
      icon: "warning",
      title: title,
      showCancelButton: true,
      confirmButtonColor: "#1C2D59",
      cancelButtonColor: "#E72E41",
      confirmButtonText: Dictionary.GetString("apply"),
      cancelButtonText: Dictionary.GetString("cancel"),
    }).then((Result) => {
      if (Result.isConfirmed) {
        return true;
      } else {
        return false;
      }
    });
  },
  InputAlert: function() {
    return Swal.fire({
      input: "number",
      inputLabel: Dictionary.GetString("userroleD4"),
      inputPlaceholder: Dictionary.GetString("userroleD4"),
      confirmButtonText: Dictionary.GetString("apply"),
      icon: "warning",
      inputAttributes: {
        style: "text-align:center;",
      },
    }).then((Result) => {
      if (Result) {
        return Number(Result.value || 0);
      }
    });
  },
  InputAlertText: function(Text) {
    return Swal.fire({
      input: "textarea",
      inputLabel: Dictionary.GetString("notes"),
      inputValue: Text == null ? "" : Text,
      inputPlaceholder: Text == null ? Dictionary.GetString("notes") : Text,
      confirmButtonText: Dictionary.GetString("apply"),
      icon: "info",
      inputAttributes: {
        style: "text-align:center;",
      },
    }).then((Result) => {
      if (Result) {
        return Result.value;
      }
    });
  },
  CheckBoxAlert: function() {
    var LSSettings = JSON.parse(localStorage.getItem(this.LSSettings()));
    return Swal.fire({
      title: Dictionary.GetString("printnow"),
      icon: "warning",
      input: "checkbox",
      inputValue: LSSettings.Checkout.AutoPrintSaveCart,
      inputPlaceholder: Dictionary.GetString("printnow"),
      confirmButtonText: Dictionary.GetString("apply"),
    }).then((Result) => {
      if (Result.value == 1) {
        return true;
      } else {
        return false;
      }
    });
  },
  TSESubmit: function(MustPay, LiekasseData, PrivateSetting, ATs, ClientID) {
    return axios
      .post(
        this.LiekasseDomain() + window.atob(this.API7()),
        LiekasseData,
        this.GetHeaderValue(
          JSON.stringify(ATs.LiekasseAT)
            .substring(1)
            .slice(0, -1)
        )
      )
      .then((LiekasseResult) => {
        console.log(LiekasseResult);
        var Final = new Object();
        Final.OrderId = LiekasseResult.data.order_id;
        Final.PrintReady = true;
        return Final;
      })
      .catch((Error) => {
        console.log("Error 3 : ", Error);
      });
  },
  ReloadPage: function() {
    return window.location.reload();
  },
};
