<template>
  <div class="pa-5 background">
    <Title :title="Dictionary.GetString('PointSaleanagement')" />
    <v-row justify="center" align="center" class="mb-1 mt-1">
      <v-col cols="5">
        <v-pagination circle v-model="PostalCodes.Settings.CurrentPage" v-bind:class="$vuetify.breakpoint.sm ? '' : 'float-start'" :length="PostalCodes.Settings.Total" v-bind:total-visible="$vuetify.breakpoint.sm ? 7 : 12" next-icon="mdi-menu-right" prev-icon="mdi-menu-left" @input="GetPostalCodes()"></v-pagination>
      </v-col>
      <v-col cols="6">
        <v-select
          class="mx-5"
          :label="Dictionary.GetString('recordsperpage')"
          v-model="PostalCodes.Settings.RowsPerPage"
          filled
          rounded
          hide-details=""
          dense
          prepend-inner-icon="mdi-library-shelves"
          :menu-props="{ offsetY: true, closeOnClick: true }"
          :items="[
            { name: '10', value: 10 },
            { name: '20', value: 20 },
            { name: '30', value: 30 },
            { name: Dictionary.GetString('showall'), value: 1000000000000000 },
          ]"
          item-text="name"
          item-value="value"
          @change="GetPostalCodes()"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="1" v-bind:class="$vuetify.breakpoint.sm ? 'text-center' : 'text-end'">
        <v-btn v-if="user.Liekasse[0].name == SuperAdmin" color="info" small v-bind:fab="$vuetify.breakpoint.sm ? false : true" @click="[(PostalCode.Type = 'New'), (PostalCode.Open = true), (PostalCode.Item = {}), (PostalCode.Title = Dictionary.GetString('Addpostalcode'))]">
          <v-icon>mdi-pencil-plus-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="PostalCode.Open" width="60vw">
      <v-container class="background pa-3" fluid>
        <v-form ref="InputForm" lazy-validation>
          <v-card flat>
            <v-card-title>
              <v-row align="center" justify="start" class="my-0 mx-1 caption">
                <h1 class="font-weight-regular font-weight-bold"><v-icon large v-bind:class="$vuetify.rtl ? 'ml-5' : 'mr-5'">mdi-pencil-plus-outline</v-icon>{{ PostalCode.Title }}</h1>
              </v-row>
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row align="center" justify="center">
                <v-col cols="4">
                  <v-text-field dense single-line class="font-weight-bold text-xl-caption" hide-details filled rounded prepend-inner-icon="mdi-star" :label="Dictionary.GetString('Postalcode')" hide-spin-buttons type="number" v-model="PostalCode.Item.postal_code" :rules="[(v) => !!v || Dictionary.GetString('noempty')]"> </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field hide-details dense class="font-weight-bold text-xl-caption" single-line filled rounded prepend-inner-icon="mdi-star" :label="Dictionary.GetString('shippingcost')" hide-spin-buttons type="number" v-model="PostalCode.Item.shipping_cost" :rules="[(v) => !!v || Dictionary.GetString('noempty')]"> </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field hide-details dense class="font-weight-bold text-xl-caption" single-line filled rounded prepend-inner-icon="mdi-star" :label="Dictionary.GetString('minorder')" hide-spin-buttons type="number" v-model="PostalCode.Item.min_order" :rules="[(v) => !!v || Dictionary.GetString('noempty')]"> </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field hide-details dense class="font-weight-bold text-xl-caption" single-line filled rounded prepend-inner-icon="mdi-star" :label="Dictionary.GetString('freeshippingfrom')" hide-spin-buttons type="number" v-model="PostalCode.Item.free_shipping_from" :rules="[(v) => !!v || Dictionary.GetString('noempty')]"> </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field hide-details dense class="font-weight-bold text-xl-caption" single-line filled rounded prepend-inner-icon="mdi-star" :label="Dictionary.GetString('Deliverytime')" hide-spin-buttons type="number" v-model="PostalCode.Item.duration" :rules="[(v) => !!v || Dictionary.GetString('noempty')]"> </v-text-field>
                </v-col>
              
              </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <v-row align="center" justify="center" class="my-1">
                <v-btn large class="mx-2 mt-3" color="error" @click="[(PostalCode.Type = ''), (PostalCode.Open = false), (PostalCode.Item = {}), (PostalCode.Title = '')]" rounded>
                  <v-icon color="white" v-bind:class="$vuetify.rtl ? 'ml-5' : 'mr-5'">mdi-close-circle-outline</v-icon>
                  {{ Dictionary.GetString("cancel") }}
                </v-btn>
                <v-btn large class="mx-2 mt-3" color="success" @click="Submit(PostalCode.Item)" rounded>
                  <v-icon color="white" v-bind:class="$vuetify.rtl ? 'ml-5' : 'mr-5'">mdi-content-save-all-outline </v-icon>
                  {{ Dictionary.GetString("save") }}
                </v-btn>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-container>
    </v-dialog>
    <v-data-table  :no-results-text="Dictionary.GetString('noresult')" :no-data-text="Dictionary.GetString('noresult')" :headers="PostalCodes.Header" item-key="id" must-sort :loading="PostalCodes.Loading" :loading-text="Dictionary.GetString('loading')" hide-default-footer :items-per-page="PostalCodes.Settings.RowsPerPage" :items="PostalCodes.Items" class="elevation-1 font-weight-bold text-xl-caption">
      <template v-slot:[`item.actions`]="{ item }">
            <!-- <v-switch v-model="PostalCode.Sto" flat class="" :label="Dictionary.GetString('available')">
            </v-switch> -->
     
        <v-btn v-if="user.Liekasse[0].name == SuperAdmin" x-small fab elevation="0" color="info" class="mx-1 my-1" dark @click="[(PostalCode.Type = 'Old'), (PostalCode.Open = true), (PostalCode.Item = item), (PostalCode.Title = Dictionary.GetString('edit'))]">
          <v-icon> mdi-pencil </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>
  
  <script>
import GeneralClasses from "../../../SubClasses/GeneralClasses.js";
import Dictionary from "../../../SubClasses/Dictionary.js";
import Title from "../../Shared/Title.vue";

export default {
  props: ["user"],
  name: "point_sale_mangmant",
  components: { Title },
  data() {
    return {
      GeneralClasses: GeneralClasses,
      Dictionary: Dictionary,
      LSSettings: JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())),

      InstallValues: {
        LiekasseAT: window.atob(JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())).ATs.LiekasseAT),
      },
      SuperAdmin: "Super Admin",
      PostalCode: {
        Title: "",
        Open: false,
        Item: {},
        Type: "",
      },
      StoreID: this.user.PrivateSetting.Liefood.StoreID,

      PostalCodes: {
        Loading: true,

        Header: [
          {
            text: "#",
            align: "start",
            value: "id",
          },
          {
            text: Dictionary.GetString("Marketname"),

            value: "store_name",
          },
          {
            text: Dictionary.GetString("city"),

            value: "city",
          },
          {
            text: Dictionary.GetString("Postalcode"),

            value: "postal_code",
          },
          {
            text: Dictionary.GetString("minorder"),
            value: "min_order",
          },
          {
            text: Dictionary.GetString("shippingcost"),
            value: "shipping_cost",
          },
          {
            text: Dictionary.GetString("freeshippingfrom"),
            value: "free_shipping_from",
          },
          {
            text: Dictionary.GetString("Deliverytime"),
            value: "duration",
          },

          {
            text: "Street",
            value: "store_postal_street",
          },
          {
            text: "Street_NR",
            value: "store_postal_street_nr",
          },
          { text: "Actions", align: "end", value: "actions" },
        ],
        Items: [],
        Settings: {
          RowsPerPage: 50,
          CurrentPage: 1,
          Total: 0,
        },
      },
    };
  },
  watch: {
    user(Value) {
      this.user = Value;
    },
  },
  methods: {
    Submit(Item) {
      if (this.$refs.InputForm.validate()) {
        const Data = new FormData();
        Data.append("postal_code_id", Item.id),
          Data.append("active", 1),
          Data.append("market_id", this.StoreID),
          Object.keys(Item).forEach((Record) => {
            Data.append(Record, Item[Record]);
          });
        var Source = this.PostalCode.Type == "New" ? this.GeneralClasses.LiefoodDomain() + window.atob(this.GeneralClasses.APILiefood2()) : this.GeneralClasses.LiefoodDomain() + window.atob(this.GeneralClasses.APILiefood3());
        this.$http
          .post(Source, Data)
          .then((Result) => {
            this.$toast.open({
              message: this.Dictionary.GetString("done"),
              type: "success",
              position: "bottom",
            });

            this.GetPostalCodes();
            this.PostalCode.Type = "";
            this.PostalCode.Open = false;
            this.PostalCode.Item = {};
            this.PostalCode.Title = "";
          })
          .catch((Error) => {
            this.$toast.open({
              message: Error.message,
              type: "error",
              position: "bottom",
            });
          });
      }
    },
    GetPostalCodes: function () {
      this.PostalCodes.Loading = true;
      this.PostalCodes.Items = [];
      this.$http
        .get(this.GeneralClasses.LiefoodDomain() + window.atob(this.GeneralClasses.APILiefood1()) + this.StoreID + "?page=" + this.PostalCodes.Settings.CurrentPage, this.GeneralClasses.GetHeaderValue(this.PostalCodes.Settings.RowsPerPage))
        .then((Result) => {
          console.log(Result.data.data);
          this.PostalCodes.Items = Result.data.data;
          this.PostalCodes.Settings.Total = Result.data.meta.last_page;
          this.PostalCodes.Loading = false;
        })
        .catch((Error) => console.log("Error 0 : ", Error));
        this.PostalCodes.Loading = false;
    },
  },
  mounted() {
    this.GetPostalCodes();
  },
};
</script>