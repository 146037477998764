<template>
  <div class="pa-5">
    <v-dialog v-model="Calc.Open" width="80vw">
      <v-container class="background pa-7" fluid>
        <Calc v-if="Calc.Open" />
        <v-row align="center" justify="center" class="mb-1">
          <v-btn large class="mx-2 mt-3" color="error" @click="Calc.Open = false" rounded>
            <v-icon color="white" v-bind:class="$vuetify.rtl ? 'ml-5' : 'mr-5'">mdi-close-circle-outline</v-icon>
            {{ Dictionary.GetString("cancel") }}
          </v-btn>
          <v-btn large class="mx-2 mt-3" color="success" @click="CalcSave(Calc.Switcher)" rounded>
            <v-icon color="white" v-bind:class="$vuetify.rtl ? 'ml-5' : 'mr-5'">mdi-content-save-all-outline</v-icon>
            {{ Dictionary.GetString("save") }}
          </v-btn>
        </v-row>
      </v-container>
    </v-dialog>
    <v-dialog v-model="Print.Open" width="80vw">
      <v-container class="background pa-7" fluid>
        <PrintsBox v-if="Print.Open" :record="Print.Item" :user="user" />
      </v-container>
    </v-dialog>
    <v-dialog v-model="PrintZ.Open" width="80vw">
      <v-container class="background pa-7" fluid>
        <PrintZZ v-if="PrintZ.Open" :record="PrintZ.Item" :user="user" :first_invoice="Report.from" :last_invoice="Report.to" />
      </v-container>
    </v-dialog>

    <Title :title="Dictionary.GetString('financialreport')" />

    <v-row align="center" class="mt-2 mb-2">
      <v-col cols="12"> </v-col>
      <v-col cols="3">
        <v-menu v-model="Report.menuFrom" :close-on-content-click="false" :nudge-right="60" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :label="$Dictionary.GetString('fromdate')" autocomplete="new-date" v-model="Report.from" :rules="[(v) => !!v || $Dictionary.GetString('noempty')]" prepend-inner-icon="mdi-calendar" filled rounded hide-details="auto" dense single-line v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="Report.from" @input="Report.menuFrom = false"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="3">
        <v-menu v-model="Report.menuTo" :close-on-content-click="false" :nudge-right="60" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :label="$Dictionary.GetString('todate')" autocomplete="new-date" v-model="Report.to" :rules="[(v) => !!v || $Dictionary.GetString('noempty')]" prepend-inner-icon="mdi-calendar" filled rounded hide-details="auto" dense single-line v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="Report.to" @input="Report.menuTo = false"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="3">
        <v-btn block height="40" rounded color="primary" :disabled="(Report.from && Report.to) == false" @click="GetReport()"> <v-icon> mdi-printer</v-icon>&nbsp;&nbsp;{{ Dictionary.GetString("financialreport") }} </v-btn>
      </v-col>
      <v-col cols="3">
        <v-btn block height="40" rounded color="error" :disabled="(Report.from && Report.to) == false" @click="GetReportZ()"> <v-icon> mdi-printer</v-icon>&nbsp;&nbsp;{{ Dictionary.GetString("thereport") }} </v-btn>
      </v-col>
      <v-col cols="3" align="center">
        <v-btn block height="40" rounded color="orange" class="white--text" :disabled="(Report.from && Report.to) == false" @click="GetReportMonth()"> <v-icon> mdi-printer</v-icon>&nbsp;&nbsp;{{ Dictionary.GetString("Monthlyreport") }} </v-btn>
      </v-col>
    </v-row>

    <Title :title="Dictionary.GetString('managebox')" />
    <v-card class="my-5" flat>
      <v-card-actions large class="headline grey darken-3 mb-2 Block white--text rounded-pill px-3 py-2"> <v-icon class="mt-n1" dark>mdi-clock-outline</v-icon> &nbsp;&nbsp; {{ $Moment(String(new Date())).format("HH:mm:ss - dddd DD.MM.YYYY") }} </v-card-actions>
      <v-card-actions>
        <v-row>
          <v-col cols="12" md="3" class="text-center secondary white--text rounded-pill mt-2 height">
            Ausgabe
            <div>{{ Number(Box.Status.Ausgabe).toFixed(2) }} €</div>
          </v-col>
          <v-col cols="12" md="3" class="text-center secondary white--text rounded-pill mt-2 height">
            Wechselgeld
            <div>{{ Number(Box.Status.Wechselg).toFixed(2) }} €</div>
          </v-col>
          <v-col cols="12" md="3" class="text-center secondary white--text rounded-pill mt-2 height">
            Soll Wert
            <div>{{ Number(Box.Status.Soll_wert).toFixed(2) }} €</div>
          </v-col>
          <v-col cols="12" md="3" class="text-center secondary white--text rounded-pill mt-2 height">
            Karte
            <div>{{ Number(Box.Status.Karte).toFixed(2) }} €</div>
          </v-col>

          <v-col cols="12" md="2" class="text-center warning white--text rounded-pill mt-2 height">
            Umsatz 19%
            <div>{{ Number(Box.Status.Umsatz_19).toFixed(2) }} €</div>
          </v-col>
          <v-col cols="12" md="2" class="text-center warning white--text rounded-pill mt-2 height">
            Umsatz 7%
            <div>{{ Number(Box.Status.Umsatz_7).toFixed(2) }} €</div>
          </v-col>
          <v-col cols="12" md="2" class="text-center warning white--text rounded-pill mt-2 height">
            Umsatz
            <div>{{ Number(Box.Status.Umsatz).toFixed(2) }} €</div>
          </v-col>

          <v-col cols="12" md="3" class="text-center warning white--text rounded-pill mt-2 height">
            MWST 19%
            <div>{{ Number(Box.Status.MWST_19).toFixed(2) }} €</div>
          </v-col>
          <v-col cols="12" md="3" class="text-center warning white--text rounded-pill mt-2 height">
            MWST 7%
            <div>{{ Number(Box.Status.MWST_7).toFixed(2) }} €</div>
          </v-col>

          <v-col cols="12" md="2" class="text-center info white--text rounded-pill mt-2 height">
            Umtausch MWST 19%
            <div>{{ Number(Box.Status.Umtausch_MWST_19).toFixed(2) }} €</div>
          </v-col>
          <v-col cols="12" md="2" class="text-center info white--text rounded-pill mt-2 height">
            Umtausch MWST 7
            <div>{{ Number(Box.Status.Umtausch_MWST_7).toFixed(2) }} €</div>
          </v-col>
          <v-col cols="12" md="2" class="text-center info white--text rounded-pill mt-2 height">
            Umtausch 19%
            <div>{{ Number(Box.Status.Umtausch_19).toFixed(2) }} €</div>
          </v-col>
          <v-col cols="12" md="3" class="text-center info white--text rounded-pill mt-2 height">
            Umtausch 7%
            <div>{{ Number(Box.Status.Umtausch_7).toFixed(2) }} €</div>
          </v-col>
          <v-col cols="12" md="3" class="text-center info white--text rounded-pill mt-2 height">
            Umtausch
            <div>{{ Number(Box.Status.Umtausch).toFixed(2) }} €</div>
          </v-col>
          <v-col cols="12" md="2" class="text-center error white--text rounded-pill mt-2 height">
            S_Storno
            <div>{{ Number(Box.Status.S_Storno).toFixed(2) }} €</div>
          </v-col>
          <v-col cols="12" md="2" class="text-center error white--text rounded-pill mt-2 height">
            Bonn_Abbr
            <div>{{ Number(Box.Status.Bonn_Abbr).toFixed(2) }} €</div>
          </v-col>
          <v-col cols="12" md="2" class="text-center error white--text rounded-pill mt-2 height">
            Pfand
            <div>{{ Number(Box.Status.pfand).toFixed(2) }} €</div>
          </v-col>
          <v-col cols="12" md="2" class="text-center error white--text rounded-pill mt-2 height">
            Pfand Tax
            <div>{{ Number(Box.Status.pledge_tax).toFixed(2) }} €</div>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-divider />
    <v-form autocomplete="off" @submit.prevent class="my-5">
      <v-text-field rounded clearable @click="[(Calc.Open = true), (Calc.Switcher = 0)]" @click:append="[(Calc.Open = true), (Calc.Switcher = 0)]" append-icon="mdi-calculator" filled single-line dense hide-details="" prepend-inner-icon="mdi-offer" :label="Dictionary.GetString('boxtotal')" v-model="Box.Value"> </v-text-field>
    </v-form>
    <v-divider />
    <v-row justify="center" align="center" class="my-5" no-gutters>
      <v-col cols="12" md="4" class="px-2">
        <v-btn color="error" rounded block @click="ResetBox(1)">{{ Dictionary.GetString("boxreset") }}</v-btn>
      </v-col>
      <v-col cols="12" md="4" class="px-2">
        <v-btn color="success" rounded block @click="ResetBox(2)">{{ Dictionary.GetString("boxfill") }}</v-btn>
      </v-col>
      <v-col cols="12" md="4" class="px-2">
        <v-btn color="warning" rounded block @click="ResetBox(3)">
          {{ Dictionary.GetString("boxdraw") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-divider />
    <v-row justify="center" align="center" class="mb-1 mt-1">
      <v-col cols="12" md="8">
        <v-pagination circle v-model="Box.Settings.CurrentPage" v-bind:class="$vuetify.breakpoint.sm ? '' : 'float-start'" :length="Box.Settings.Total" v-bind:total-visible="$vuetify.breakpoint.sm ? 7 : 12" next-icon="mdi-menu-right" prev-icon="mdi-menu-left" @input="GetBoxAPI()"></v-pagination>
      </v-col>
      <v-col cols="12" md="4">
        <v-select
          class="mx-4"
          :label="Dictionary.GetString('recordsperpage')"
          v-model="Box.Settings.RowsPerPage"
          filled
          rounded
          hide-details=""
          dense
          prepend-inner-icon="mdi-library-shelves"
          :menu-props="{ offsetY: true, closeOnClick: true }"
          :items="[
            { name: '50', value: 50 },
            { name: '75', value: 75 },
            { name: '100', value: 100 },
            { name: Dictionary.GetString('showall'), value: 1000000000000000 },
          ]"
          item-text="name"
          item-value="value"
          @change="GetBoxAPI()"
        ></v-select>
      </v-col>
    </v-row>
    <v-data-table v-if="Box.List" :no-results-text="Dictionary.GetString('noresult')" :no-data-text="Dictionary.GetString('noresult')" :headers="Box.Header" item-key="id" must-sort :sort-desc="true" :loading="Box.Loading" :loading-text="Dictionary.GetString('loading')" hide-default-footer :items-per-page="Box.Settings.RowsPerPage" sort-by="id" :items="Box.Items" class="elevation-1">
      <template v-slot:[`item.created_at`]="{ item }">
        <v-chip dark>{{ $Moment(String(item.created_at)).format("DD.MM.YYYY") }}</v-chip>
      </template>
      <template v-slot:[`item.details`]="{ item }">
        <div class="my-3">
          <v-chip large class="mb-2 Block rounded-pill px-3 overline" style="height: 38px"> <v-icon class="mt-n1" dark>mdi-clock-outline</v-icon> &nbsp;&nbsp;{{ $Moment(String(item.created_at)).format("HH:mm:ss - dddd DD.MM.YYYY") }} </v-chip>
          <v-chip class="mx-1 success rounded-pill overline">NR : {{ Number(item.id) }} </v-chip>
          <v-chip class="mx-1 secondary rounded-pill overline">Ausgabe : {{ Number(item.Ausgabe).toFixed(2) }} € </v-chip>
          <v-chip class="mx-1 secondary rounded-pill overline">Wechselgeld : {{ Number(item.Wechselg).toFixed(2) }} € </v-chip>
          <v-chip class="mx-1 secondary rounded-pill overline">Soll Wert : {{ Number(item.Soll_wert).toFixed(2) }} € </v-chip>
          <v-chip class="mx-1 secondary rounded-pill overline">Ist Wert : {{ Number(item.Ist_Wert).toFixed(2) }} € </v-chip>
          <v-chip class="mx-1 secondary rounded-pill overline">Diffrenz : {{ Number(item.Diffrenz).toFixed(2) }} € </v-chip>
          <v-chip class="mx-1 secondary rounded-pill overline">Karte : {{ Number(item.Karte).toFixed(2) }} €</v-chip>
          <v-divider class="my-2" />
          <v-chip class="mx-1 warning rounded-pill overline">Umsatz 19% : {{ Number(item.Umsatz_19).toFixed(2) }} € </v-chip>
          <v-chip class="mx-1 warning rounded-pill overline">Umsatz 7% : {{ Number(item.Umsatz_7).toFixed(2) }} € </v-chip>
          <v-chip class="mx-1 warning rounded-pill overline">Umsatz : {{ Number(item.Umsatz).toFixed(2) }} €</v-chip>
          <v-chip class="mx-1 warning rounded-pill overline">MWST 19% : {{ Number(item.MWST_19).toFixed(2) }} € </v-chip>
          <v-chip class="mx-1 warning rounded-pill overline">MWST 7% : {{ Number(item.MWST_7).toFixed(2) }} € </v-chip>
          <v-divider class="my-2" />
          <v-chip class="mx-1 info rounded-pill overline">Umtausch 19% : {{ Number(item.Umtausch_19).toFixed(2) }} € </v-chip>
          <v-chip class="mx-1 info rounded-pill overline">Umtausch 7% : {{ Number(item.Umtausch_7).toFixed(2) }} € </v-chip>
          <v-chip class="mx-1 info rounded-pill overline">Umtausch : {{ Number(item.Umtausch).toFixed(2) }} € </v-chip>
          <v-chip class="mx-1 info rounded-pill overline">Umtausch MWST 19% :{{ Number(item.Umtausch_MWST_19).toFixed(2) }} € </v-chip>
          <v-chip class="mx-1 info rounded-pill overline">Umtausch MWST 7% :{{ Number(item.Umtausch_MWST_7).toFixed(2) }} € </v-chip>
          <v-divider class="my-2" />
          <v-chip class="mx-1 error rounded-pill overline">S_Storno : {{ Number(item.S_Storno).toFixed(2) }} € </v-chip>
          <v-chip class="mx-1 error rounded-pill overline">Bonn_Abbr : {{ Number(item.Bonn_Abbr).toFixed(2) }} € </v-chip>
          <v-chip class="mx-1 error rounded-pill overline">Pfand: {{ Number(item.pfand).toFixed(2) }} €</v-chip>
          <v-chip class="mx-1 error rounded-pill overline">Pfand_Tax: {{ Number(item.pledge_tax).toFixed(2) }} € </v-chip>
        </div>
      </template>
      <template v-slot:[`item.value`]="{ item }">
        <v-chip class="Block text-end mt-2" v-bind:class="item.type == 1 ? 'success' : 'error'">{{ Number(item.value).toFixed(2) }} € </v-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn x-small fab elevation="0" class="info mx-1 my-1" @click="[(Print.Open = true), (Print.Item = item)]">
          <v-icon> mdi-printer</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
// JS Scripts
import GeneralClasses from "../../SubClasses/GeneralClasses.js";
import Dictionary from "../../SubClasses/Dictionary.js";

// Components
import Title from "../Shared/Title.vue";
import Calc from "../Shared/Calc.vue";
import PrintsBox from "../Shared/Prints/PrintsBox.vue";
import PrintZZ from "../Shared/Prints/PrintZZ.vue";
export default {
  props: ["user"],
  name: "Box",
  components: { Title, Calc, PrintsBox, PrintZZ },
  data() {
    return {
      GeneralClasses: GeneralClasses,
      Dictionary: Dictionary,
      LSSettings: JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())),

      InstallValues: {
        LiekasseAT: window.atob(JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())).ATs.LiekasseAT),
      },
      Report: {
        from: "",
        to: "",
        menuFrom: false,
        menuTo: false,
      },
      Time: null,

      Calc: {
        Open: false,
        Switcher: null,
      },

      Print: {
        Open: false,
        Item: null,
      },
      PrintZ: {
        Open: false,
        Item: null,
      },
      Box: {
        Value: "",
        Search: "",
        Loading: true,
        List: true,
        Header: [
          {
            text: "#",
            align: "start",
            value: "created_at",
            width: "60px",
          },
          {
            text: Dictionary.GetString("installgeneralinfos"),
            align: "start",
            value: "details",
            sortable: false,
          },
          {
            text: "Actions",
            align: "end",
            value: "actions",
            sortable: false,
            filterable: false,
          },
        ],
        Items: [],
        Status: [],
        Settings: {
          RowsPerPage: 50,
          CurrentPage: 1,
          Total: 0,
        },
      },
    };
  },
  watch: {
    user(Value) {
      this.user = Value;
    },
  },
  methods: {
    GetReport: function () {
      this.Report.from = this.Report.from + " 00:00:00";
      this.Report.to = this.Report.to + " 23:59:59";
      this.$http.post(this.GeneralClasses.LiekasseDomain() + window.atob(this.GeneralClasses.API19()), this.Report, this.GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT)).then((Result) => {
        this.Print.Open = true;
        Result.data.fromto = this.$Moment(String(this.Report.from)).format("HH:mm:ss dddd DD.MM.YYYY") + " - " + this.$Moment(String(this.Report.to)).format("HH:mm:ss dddd DD.MM.YYYY");
        this.Print.Item = Result.data;
      });
    },
    GetReportZ: function () {
      this.Report.from = this.Report.from + " 00:00:00";
      this.Report.to = this.Report.to + " 23:59:59";
      this.$http
        .post(
          this.GeneralClasses.LiekasseDomain() + window.atob(this.GeneralClasses.API22()),
          this.Report,

          this.GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT)
        )
        .then((Result) => {
          this.PrintZ.Open = true;
          Result.data.fromto = this.$Moment(String(this.Report.from)).format("HH:mm:ss dddd DD.MM.YYYY") + " - " + this.$Moment(String(this.Report.to)).format("HH:mm:ss dddd DD.MM.YYYY");
          this.PrintZ.Item = Result.data;
        });
    },
    CustomSubtitle: function (obj, fontSize, title, Space) {
      obj.setFontSize(fontSize);
      obj.text(title, 5, Space);
    },
    GetReportMonth: function () {
      var Axios = {
        url: this.GeneralClasses.LiekasseDomain() + window.atob(this.GeneralClasses.APIBoxReport()),
        method: "GET",
        headers: {
          Authorization: "Bearer " + this.InstallValues.LiekasseAT,
          from: this.Report.from,
          to: this.Report.to,
        },
      };
      this.$http(Axios)
        .then((Result) => {
          const boxData = Result.data.data;
          if (Result.data.data.length == 0) {
            this.$GeneralClasses.ConfirmAlert(this.$Dictionary.GetString("No Data")).then((Result) => {
              if (Result) {
                //
              }
            });
          } else {
            const doc = new jsPDF("landscape");

            const space = 5;
            var count = 0;
            count = count + 5;
            this.CustomSubtitle(doc, 10, "Kassenbericht " + this.Report.from + " - " + this.Report.to, count);
            count = count + 5;
            this.CustomSubtitle(doc, 5, "TSE Name : " + this.user.PublicSetting.Name, count);
            count = count + 5;
            this.CustomSubtitle(doc, 5, "TSE Addresse : " + this.user.PublicSetting.Address + " - " + this.user.PublicSetting.Zip, count);
            count = count + 5;
            this.CustomSubtitle(doc, 5, "Datum : " + this.Time, count);
            count = count + 5;
            this.CustomSubtitle(doc, 5, "Zeitzum : " + this.Report.from + " - " + this.Report.to, count);
            count = count + 5;
            this.CustomSubtitle(doc, 5, "TSE ID : " + this.user.PrivateSetting.TSE2ID, count);

            const modifiedBoxData = boxData.map((obj) => {
              delete obj.id;
              delete obj.Ausgabe;
              // Check if Attribute of obj isNaN
              for (var key in obj) {
                if (!isNaN(obj[key])) {
                  obj[key] = parseFloat(obj[key]).toFixed(2);
                }
              }
              obj.created_at = this.$Moment(obj.created_at).format("DD.MM.YYYY");
              return Object.values(obj);
            });

            console.log("modifiedBoxData : " + modifiedBoxData);

            const headers = Object.keys(boxData[0]);
            console.log(headers);
            var newHeaders = [];
            for (var i = 0; i < headers.length; i++) {
              newHeaders.push({
                header: headers[i].replace("created_at", "Tag").replace("details", "Details").replace("Karte", "Karte Zahlung").replace("Umsatz_19", "Umsatz 19%").replace("Umsatz_7", "Umsatz 7%").replace("MWST_19", "Mwst. 19%").replace("MWST_7", "Mwst.7%").replace("Bonn_Abbr", "Summe Bonn-Abbr").replace("Bonn_Abbr_Belege", "Summe Bohn-Abbr Belege").replace("Umtausch_19", "Umtausch 19%").replace("Umtausch_7", "Umtausch 7%").replace("Umtausch_MWST_19", "Umtausch_MWST_19%").replace("Umtausch_MWST_7", "Umtausch_MWST_7%").replace("pledge_tax", "Pfand-Mwst.19%").replace("pfand", "Pfand").replace("first_invoice", "Erste Beleg Nr").replace("last_invoice", "Letzte Beleg Nr").replace("_", " "),
                dataKey: headers[i],
              });
            }

            doc.autoTable({
              columns: newHeaders,
              body: modifiedBoxData,
              margin: { top: space, right: space, bottom: space, left: space },
              startY: count + 5,
              startX: space,
              tableWidth: "auto",
              didParseCell: function (data) {
                data.cell.styles.fontSize = 4;
              },
            });
            doc.save("data.pdf");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    CalcSave(Object) {
      var LSSettings = JSON.parse(localStorage.getItem(this.GeneralClasses.LSSettings()));
      if (LSSettings.Calc != null) {
        switch (Object) {
          case 0:
            this.Box.Value = LSSettings.Calc;
            break;
          default:
          // Do Nothing
        }
      }
      this.Calc.Open = false;
    },
    ResetBox(Type) {
      this.GeneralClasses.ConfirmAlert(this.$Dictionary.GetString("sure")).then((Result) => {
        if (Result) {
          // 1 : Reset
          // 2 : Fill
          // 3 : Draw (Ausgabe)
          this.$toast.open({
            message: this.Dictionary.GetString("done"),
            type: "success",
            position: "bottom",
          });
          var API = "";
          var Data = "";
          if (Type == 1) {
            API = window.atob(this.GeneralClasses.API16());
            Data = { reset_at: Number(this.Box.Value) };
          } else if (Type == 2) {
            API = window.atob(this.GeneralClasses.API17());
            Data = { fill_with: Number(this.Box.Value) };
          } else if (Type == 3) {
            API = window.atob(this.GeneralClasses.API17Ausgabe());
            Data = { Ausgabe: Number(this.Box.Value) };
          }
          this.$http
            .post(this.GeneralClasses.LiekasseDomain() + API, Data, this.GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT))
            .then((Result) => {
              this.GetBoxInfo();
              this.GetBoxAPI();
            })
            .catch((Error) => console.log("Error 0 : ", Error));
        }
      });
    },
    GetBoxAPI: function () {
      this.Box.Loading = true;
      this.Box.Items = [];
      this.$http
        .get(this.GeneralClasses.LiekasseDomain() + window.atob(this.GeneralClasses.API14()) + "?page=" + this.Box.Settings.CurrentPage, this.GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT, this.Box.Settings.RowsPerPage))
        .then((Result) => {
          this.Box.Items = Result.data.data;
          this.Box.Settings.Total = 1;
          this.Box.Loading = false;
          this.Box.Value = Number(this.Box.Status.Soll_wert).toFixed(2);
        })
        .catch((Error) => console.log("Error 0 : ", Error));
    },
    GetBoxInfo: function () {
      this.Box.Loading = true;
      this.Box.Items = [];
      this.$http
        .get(this.GeneralClasses.LiekasseDomain() + window.atob(this.GeneralClasses.API13()) + "1", this.GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT))
        .then((ResultStatus) => {
          this.Box.Status = ResultStatus.data.data;
        })
        .catch((Error) => console.log("Error 0 : ", Error));
    },
  },
  mounted() {
    this.GetBoxInfo();
    this.GetBoxAPI();
    this.Time = this.$Moment().format("DD.MM.YYYY");
  },
};
</script>
<style scoped>
.height {
  height: 63px;
}
</style>