
<template>
  <v-app>
    <v-container fluid>
      <v-card-title>
        <v-col cols="12" align="center" class="fill-height d-flex justify-center align-center" justify="center" style="height: 65vh">
          <v-card class="elevation-2" width="35%" color="#F7FAFC">
            <v-form ref="form" lazy-validation>
              <v-col class="pt-2">
                <v-row>
                  <v-spacer></v-spacer>
                  <v-col cols="12" md="5" align="start">
                    <v-card-subtitle class="px-2 py-3" style="color: #6b7c93">Name </v-card-subtitle>
                    <v-text-field :label="$Dictionary.GetString('name')" background-color="white" v-model="Payment.name" class="pl-2" type="text" solo :rules="[(v) => !!v || $Dictionary.GetString('noempty')]"> </v-text-field>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="12" md="6" align="start">
                    <v-card-subtitle class="px-0 py-3" style="color: #6b7c93">Email Address </v-card-subtitle>
                    <v-text-field :label="$Dictionary.GetString('email')" background-color="white" v-model="Payment.email" type="text" solo :rules="[(v) => !!v || $Dictionary.GetString('noempty')]"> </v-text-field>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
                <v-row>
                  <v-col cols="12" md="8" class="px-10 py-0" align="start">
                    <v-card-subtitle class="px-0 py-3" style="color: #6b7c93">IBAN </v-card-subtitle>
                    <v-text-field label="DE00 0000 0000 0000 0000" class="NoNumberControll" background-color="white" v-model="Payment.iban" type="text" solo prepend-inner-icon="mdi-bank" :rules="[(v) => !!v || $Dictionary.GetString('noempty')]"> </v-text-field>
                  </v-col>
                  <v-col md="4" class="px-8 py-0" align="start">
                    <v-card-subtitle class="px-0 py-3" style="color: #6b7c93">Amount </v-card-subtitle>
                    <v-text-field class="NoNumberControll" :label="$Dictionary.GetString('amount')" background-color="white" v-model="Payment.amount" type="number" solo :rules="[(v) => !!v || $Dictionary.GetString('noempty')]"> </v-text-field>
                  </v-col>
                </v-row>
                <v-col>
                  <template v-if="Payment.Loading">
                    <Loading
                      :settings="{
                        Loading: Payment.Loading,
                        Text: $Dictionary.GetString('Paymentprogress'),
                        Color: 'white',
                      }"
                    />
                  </template>
                </v-col>
                <v-col cols="12" md="6" align="center" class="px-0 py-0">
                  <v-btn class="rounded-lg" v-if="!Payment.isHidden" :click="Payment.isHidden" width="100%" height="50px" @click="Subscribe()" color="#32325D" dark>{{ $Dictionary.GetString("subscription") }} </v-btn>
                </v-col>
              </v-col>
            </v-form>
          </v-card>
        </v-col>
      </v-card-title>
    </v-container>
  </v-app>
</template>
<script>
import GeneralClasses from "@/SubClasses/GeneralClasses.js";
import Dictionary from "../../SubClasses/Dictionary.js";
import Loading from "../Shared/Loading.vue";

export default {
  name: "Payment",
  components: { Loading },
  data: () => {
    return {
      GeneralClasses: GeneralClasses,
      Dictionary: Dictionary,
      LSSettings: JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())),

      InstallValues: {
        LiekasseAT: window.atob(JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())).ATs.LiekasseAT),
      },

      Payment: {
        name: "",
        email: "",
        iban: "",
        amount: "",
        Loading: false,
        isHidden: false,
      },
    };
  },
  methods: {
    clearInput() {
      this.$refs.form.reset();
    },
    Subscribe() {
      if (this.$refs.form.validate()) {
        this.Payment.isHidden = true;
        this.Payment.Loading = true;
        const Data = new FormData();
        Data.append("name", this.Payment.name);
        Data.append("email", this.Payment.email);
        Data.append("iban", this.Payment.iban);
        Data.append("amount", this.Payment.amount);
        var Source = window.atob(this.$GeneralClasses.API26());
        this.$http
          .post(this.$GeneralClasses.LiekasseDomain() + Source, Data, this.$GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT))
          .then((ResultUser) => {
            this.$toast.open({
              message: this.Dictionary.GetString("Paymentcompleted"),
              type: "success",
              position: "bottom",
            });

            this.Payment.Loading = false;
            this.Payment.isHidden = false;
            this.clearInput();
          })
          .catch((Error) => {
            this.$toast.open({
              message: Error,
              type: "error",
              position: "bottom",
            });
            console.log(Error);
            this.Payment.isHidden = false;
            this.Payment.Loading = false;
          });
      }
    },
  },
};
</script>