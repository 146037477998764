<template>
  <div class="pt-5 pr-3 background">
    <Title :title="$Dictionary.GetString('usermanagement')" />
    <v-form ref="Form" lazy-validation autocomplete="off">
      <v-row class="pt-3 pl-5 pr-5">
        <v-col cols="12" md="4">
          <v-text-field hide-details="" dense single-line autocomplete="new-username" filled rounded prepend-inner-icon="mdi-email" :label="Dictionary.GetString('useremail')" v-model="User.email" :rules="[(v) => !!v || Dictionary.GetString('noempty'), (v) => /.+@.+\..+/.test(v) || Dictionary.GetString('wronginfos')]"></v-text-field>
        </v-col>
        <v-col md="4">
          <v-text-field hide-details="" dense single-line autocomplete="new-password" filled rounded prepend-inner-icon="mdi-star" :append-icon="User.userpasswordshow ? 'mdi-eye' : 'mdi-eye-off'" :rules="[(v) => !!v || Dictionary.GetString('noempty')]" :type="User.userpasswordshow ? 'text' : 'password'" :label="Dictionary.GetString('userpassword')" v-model="User.password" @click:append="User.userpasswordshow = !User.userpasswordshow"></v-text-field>
        </v-col>
        <v-col cols="12" class="pa-0">
          <v-card-subtitle>
            {{ $Dictionary.GetString("updateuser") }}
          </v-card-subtitle>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field hide-details="" dense single-line autocomplete="new-password" filled rounded prepend-inner-icon="mdi-account-check-outline" :label="Dictionary.GetString('username')" v-model="UserU.name"></v-text-field>
        </v-col>

        <v-col md="4" class="pb-0">
          <v-text-field hide-details="" dense single-line autocomplete="new-password" filled rounded prepend-inner-icon="mdi-star" :append-icon="userpasswordshow ? 'mdi-eye' : 'mdi-eye-off'" :type="userpasswordshow ? 'text' : 'password'" :label="Dictionary.GetString('userpassword')" v-model="UserU.password" @click:append="userpasswordshow = !userpasswordshow"></v-text-field>
        </v-col>

        <v-col class="d-flex justify-center pa-0">
          <v-alert class="d-flex justify-center" v-if="Alerts.Message != ''" color="warning" rounded="pill" dark width="20vw">
            {{ Alerts.Message }}
          </v-alert>
        </v-col>
      </v-row>
    </v-form>
    <v-card flat class="pa-6 pl-11" min-width="100vw">
      <v-row align="center" justify="start">
        <v-col cols="12">
          <v-expansion-panels class="rounded-xl font-weight-bold">
            <v-expansion-panel key="1">
              <v-expansion-panel-header>{{ Dictionary.GetString("addorders") }} </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12" md="4" v-for="Section in InstallValues.Sections.ItemsA" :key="Section.Value">
                    <v-checkbox v-model="InstallValues.Sections.Checked" :label="Section.Label" :value="Section.Value"></v-checkbox>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel key="2">
              <v-expansion-panel-header>
                {{ Dictionary.GetString("addproducts") }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12" md="4" v-for="Section in InstallValues.Sections.ItemsB" :key="Section.Value">
                    <v-checkbox v-model="InstallValues.Sections.Checked" :label="Section.Label" :value="Section.Value"></v-checkbox>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel key="3">
              <v-expansion-panel-header>
                {{ Dictionary.GetString("addproduct") }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12" md="4" v-for="Section in InstallValues.Sections.ItemsC" :key="Section.Value">
                    <v-checkbox v-model="InstallValues.Sections.Checked" :label="Section.Label" :value="Section.Value"></v-checkbox>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel key="4">
              <v-expansion-panel-header>
                {{ user.PublicSetting.Type == 2 || user.PublicSetting.Type == 3 ? Dictionary.GetString("managecart2") : Dictionary.GetString("managecart1") }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12" md="4" v-for="Section in InstallValues.Sections.ItemsD" :key="Section.Value">
                    <v-checkbox v-model="InstallValues.Sections.Checked" :label="Section.Label" :value="Section.Value"></v-checkbox>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel key="5">
              <v-expansion-panel-header>
                {{ Dictionary.GetString("addcategories") }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12" md="4" v-for="Section in InstallValues.Sections.ItemsE" :key="Section.Value">
                    <v-checkbox v-model="InstallValues.Sections.Checked" :label="Section.Label" :value="Section.Value"></v-checkbox>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel key="6">
              <v-expansion-panel-header>
                {{ Dictionary.GetString("addorders") }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12" md="4" v-for="Section in InstallValues.Sections.ItemsF" :key="Section.Value">
                    <v-checkbox v-model="InstallValues.Sections.Checked" :label="Section.Label" :value="Section.Value"></v-checkbox>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel key="7">
              <v-expansion-panel-header>
                {{ Dictionary.GetString("managebox") }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12" md="4" v-for="Section in InstallValues.Sections.ItemsG" :key="Section.Value">
                    <v-checkbox v-model="InstallValues.Sections.Checked" :label="Section.Label" :value="Section.Value"></v-checkbox>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel key="8">
              <v-expansion-panel-header>
                {{ Dictionary.GetString("generalstatistics") }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12" md="4" v-for="Section in InstallValues.Sections.ItemsH" :key="Section.Value">
                    <v-checkbox v-model="InstallValues.Sections.Checked" :label="Section.Label" :value="Section.Value"></v-checkbox>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!-- usermanagement -->
            <v-expansion-panel key="9">
              <v-expansion-panel-header>
                {{ Dictionary.GetString("usermanagement") }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12" md="4" v-for="Section in InstallValues.Sections.ItemsJ" :key="Section.Value">
                    <v-checkbox v-model="InstallValues.Sections.Checked" :label="Section.Label" :value="Section.Value"></v-checkbox>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <v-divider class="my-5" />
      <v-btn color="success" rounded dark @click="Update()" width="100%">
        {{ Dictionary.GetString("Update Permissions") }}
      </v-btn>
    </v-card>
  </div>
</template>

<script>
// JS Scripts
import GeneralClasses from "../../SubClasses/GeneralClasses.js";
import Dictionary from "../../SubClasses/Dictionary.js";
import Title from "../Shared/Title.vue";

export default {
  name: "User_Management",
  props: ["user"],
  components: { Title },
  data() {
    return {
      GeneralClasses: GeneralClasses,
      Dictionary: Dictionary,
      LSSettings: JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())),

      InstallValues: {
        LiekasseAT: window.atob(JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())).ATs.LiekasseAT),

        Sections: {
          ItemsA: [],
          ItemsB: [],
          ItemsC: [],
          ItemsD: [],
          ItemsE: [],
          ItemsF: [],
          ItemsG: [],
          ItemsH: [],
          ItemsJ: [],
          Checked: [],
        },
      },
      Alerts: {
        Message: "",
      },
      User: {
        userpasswordshow: false,
        userpasswordconfirmationshow: false,
      },
      UserU: {},
      UserInfo: {},
      userpasswordshow: false,
      userpasswordconfirmationshow: false,
    };
  },
  methods: {
    Update: function () {
      if (this.$refs.Form.validate()) {
        var user_data = { email: this.User.email, password: this.User.password };
        this.$http
          .post(this.GeneralClasses.LiekasseDomain() + window.atob(this.GeneralClasses.API27()), user_data, this.GeneralClasses.GetHeaderValue())
          .then((User) => {
            if (User.data.id != null) {
              var user = User.data;

              this.UserInfo = JSON.parse(user.setting);
              this.UserInfo.Sections = window.btoa(JSON.stringify(this.InstallValues.Sections.Checked));
              var update_user = { email: this.User.email, password: this.UserU.password, name: this.UserU.name, setting: this.UserInfo };

              this.$http.post(this.GeneralClasses.LiekasseDomain() + window.atob(this.GeneralClasses.API28()), update_user, this.GeneralClasses.GetHeaderValue()).then((_) => {
                this.$refs.Form.reset();
                this.$toast.open({
                  message: this.Dictionary.GetString("updateuser"),
                  type: "success",
                  position: "bottom",
                });
              });
              window.location.reload();
              this.Alerts.Message = "";
              this.$refs.Form.reset();
            } else {
              this.Alerts.Message = this.Dictionary.GetString("wronginfos");
            }
          })
          .catch((Error) => {
            console.log(Error);
          });
      }
    },
    SetupRoles: function () {
      console.log();
      for (let Item = 1; Item <= 13; Item++) {
        this.InstallValues.Sections.ItemsA.push({
          Label: this.Dictionary.GetString("userroleA" + Item),
          Value: "A" + Item,
          Checked: true,
        });
        this.InstallValues.Sections.Checked.push("A" + Item);
      }
      for (let Item = 1; Item <= 8; Item++) {
        this.InstallValues.Sections.ItemsB.push({
          Label: this.Dictionary.GetString("userroleB" + Item),
          Value: "B" + Item,
          Checked: true,
        });
        this.InstallValues.Sections.Checked.push("B" + Item);
      }
      for (let Item = 1; Item <= 5; Item++) {
        this.InstallValues.Sections.ItemsC.push({
          Label: this.Dictionary.GetString("userroleC" + Item),
          Value: "C" + Item,
          Checked: true,
        });
        this.InstallValues.Sections.Checked.push("C" + Item);
      }
      for (let Item = 1; Item <= 5; Item++) {
        this.InstallValues.Sections.ItemsD.push({
          Label: this.Dictionary.GetString("userroleD" + Item),
          Value: "D" + Item,
          Checked: true,
        });
        this.InstallValues.Sections.Checked.push("D" + Item);
      }
      for (let Item = 1; Item <= 1; Item++) {
        this.InstallValues.Sections.ItemsE.push({
          Label: this.Dictionary.GetString("userroleE" + Item),
          Value: "E" + Item,
          Checked: true,
        });
        this.InstallValues.Sections.Checked.push("E" + Item);
      }
      for (let Item = 1; Item <= 5; Item++) {
        this.InstallValues.Sections.ItemsF.push({
          Label: this.Dictionary.GetString("userroleF" + Item),
          Value: "F" + Item,
          Checked: true,
        });
        this.InstallValues.Sections.Checked.push("F" + Item);
      }
      for (let Item = 1; Item <= 4; Item++) {
        this.InstallValues.Sections.ItemsG.push({
          Label: this.Dictionary.GetString("userroleG" + Item),
          Value: "G" + Item,
          Checked: true,
        });
        this.InstallValues.Sections.Checked.push("G" + Item);
      }
      for (let Item = 1; Item <= 1; Item++) {
        this.InstallValues.Sections.ItemsH.push({
          Label: this.Dictionary.GetString("userroleH" + Item),
          Value: "H" + Item,
          Checked: true,
        });
        this.InstallValues.Sections.Checked.push("H" + Item);
      }
      for (let Item = 1; Item <= 1; Item++) {
        this.InstallValues.Sections.ItemsJ.push({
          Label: this.Dictionary.GetString("userroleJ" + Item),
          Value: "J" + Item,
          Checked: true,
        });
        this.InstallValues.Sections.Checked.push("J" + Item);
      }
    },
  },
  mounted() {
    this.SetupRoles();
  },
};
</script>
