<template>
  <div>
    <v-tabs>
      <v-tab> <v-icon>mdi-account-cog-outline</v-icon> &nbsp; &nbsp;{{ Dictionary.GetString("usersadd") }} </v-tab>
      <v-tab> <v-icon>mdi-tools</v-icon> &nbsp; &nbsp;{{ Dictionary.GetString("installshopinfos") }} </v-tab>
      <v-tab-item key="0">
        <v-card flat class="pa-6 pl-11" min-width="100vw">
          <v-form ref="Create" lazy-validation autocomplete="off">
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field hide-details="" dense single-line autocomplete="new-username" filled rounded prepend-inner-icon="mdi-web" :label="Dictionary.GetString('username')" v-model="InstallValues.username" :rules="[(v) => !!v || Dictionary.GetString('noempty')]"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field hide-details="" dense single-line autocomplete="new-username" filled rounded prepend-inner-icon="mdi-email" :label="Dictionary.GetString('useremail')" v-model="InstallValues.useremail" :rules="[(v) => !!v || Dictionary.GetString('noempty'), (v) => /.+@.+\..+/.test(v) || Dictionary.GetString('wronginfos')]"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field hide-details="" dense single-line autocomplete="new-password" filled rounded prepend-inner-icon="mdi-star" :append-icon="InstallValues.userpasswordshow ? 'mdi-eye' : 'mdi-eye-off'" :rules="[(v) => !!v || Dictionary.GetString('noempty')]" :type="InstallValues.userpasswordshow ? 'text' : 'password'" :label="Dictionary.GetString('userpassword')" v-model="InstallValues.userpassword" @click:append="InstallValues.userpasswordshow = !InstallValues.userpasswordshow"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field hide-details="" dense single-line autocomplete="new-password" filled rounded prepend-inner-icon="mdi-star" :append-icon="InstallValues.userpasswordconfirmationshow ? 'mdi-eye' : 'mdi-eye-off'" :rules="[(v) => !!v || Dictionary.GetString('noempty'), this.InstallValues.userpassword === this.InstallValues.userpasswordconfirmation || Dictionary.GetString('nomatch')]" :type="InstallValues.userpasswordconfirmationshow ? 'text' : 'password'" :label="Dictionary.GetString('userpasswordconfirmation')" v-model="InstallValues.userpasswordconfirmation" @click:append="InstallValues.userpasswordconfirmationshow = !InstallValues.userpasswordconfirmationshow"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-expansion-panels class="rounded-xl font-weight-bold">
                  <v-expansion-panel key="1">
                    <v-expansion-panel-header>{{ Dictionary.GetString("addorders") }} </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col cols="12" md="4" v-for="Section in InstallValues.Sections.ItemsA" :key="Section.Value">
                          <v-checkbox v-model="InstallValues.Sections.Checked" :label="Section.Label" :value="Section.Value"></v-checkbox>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel key="2">
                    <v-expansion-panel-header>
                      {{ Dictionary.GetString("addproducts") }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col cols="12" md="4" v-for="Section in InstallValues.Sections.ItemsB" :key="Section.Value">
                          <v-checkbox v-model="InstallValues.Sections.Checked" :label="Section.Label" :value="Section.Value"></v-checkbox>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel key="3">
                    <v-expansion-panel-header>
                      {{ Dictionary.GetString("addproduct") }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col cols="12" md="4" v-for="Section in InstallValues.Sections.ItemsC" :key="Section.Value">
                          <v-checkbox v-model="InstallValues.Sections.Checked" :label="Section.Label" :value="Section.Value"></v-checkbox>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel key="4">
                    <v-expansion-panel-header>
                      {{ user.PublicSetting.Type == 2 || user.PublicSetting.Type == 3 ? Dictionary.GetString("managecart2") : Dictionary.GetString("managecart1") }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col cols="12" md="4" v-for="Section in InstallValues.Sections.ItemsD" :key="Section.Value">
                          <v-checkbox v-model="InstallValues.Sections.Checked" :label="Section.Label" :value="Section.Value"></v-checkbox>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel key="5">
                    <v-expansion-panel-header>
                      {{ Dictionary.GetString("addcategories") }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col cols="12" md="4" v-for="Section in InstallValues.Sections.ItemsE" :key="Section.Value">
                          <v-checkbox v-model="InstallValues.Sections.Checked" :label="Section.Label" :value="Section.Value"></v-checkbox>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel key="6">
                    <v-expansion-panel-header>
                      {{ Dictionary.GetString("addorders") }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col cols="12" md="4" v-for="Section in InstallValues.Sections.ItemsF" :key="Section.Value">
                          <v-checkbox v-model="InstallValues.Sections.Checked" :label="Section.Label" :value="Section.Value"></v-checkbox>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel key="7">
                    <v-expansion-panel-header>
                      {{ Dictionary.GetString("managebox") }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col cols="12" md="4" v-for="Section in InstallValues.Sections.ItemsG" :key="Section.Value">
                          <v-checkbox v-model="InstallValues.Sections.Checked" :label="Section.Label" :value="Section.Value"></v-checkbox>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel key="8">
                    <v-expansion-panel-header>
                      {{ Dictionary.GetString("generalstatistics") }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col cols="12" md="4" v-for="Section in InstallValues.Sections.ItemsH" :key="Section.Value">
                          <v-checkbox v-model="InstallValues.Sections.Checked" :label="Section.Label" :value="Section.Value"></v-checkbox>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <!-- usermanagement -->
                  <v-expansion-panel key="9">
                    <v-expansion-panel-header>
                      {{ Dictionary.GetString("usermanagement") }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col cols="12" md="4" v-for="Section in InstallValues.Sections.ItemsJ" :key="Section.Value">
                          <v-checkbox v-model="InstallValues.Sections.Checked" :label="Section.Label" :value="Section.Value"></v-checkbox>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
            <v-divider class="my-5" />
            <v-btn color="success" rounded dark @click="Create()" width="100%">
              {{ Dictionary.GetString("next") }}
            </v-btn>
          </v-form>
        </v-card>
      </v-tab-item>
      <v-tab-item key="1">
        <v-container class="ma-0 pa-0">
          <v-card flat class="pa-5" min-width="100vw">
            <v-row justify="center" align="center">
              <v-col cols="12">
                <v-switch class="mx-2" :disabled="!user.PrivateSetting.Locked" v-model="user.PrivateSetting.Locked" @change="ChangeValues('PrivateSetting', 'Locked', null, false)" inset flat :label="Dictionary.GetString('shuttingdownsystem')"></v-switch>
                <v-text-field type="date" v-model="user.PrivateSetting.Locked" autocomplete="new-date" filled rounded hide-details="auto" prepend-inner-icon="mdi-map-marker" :label="Dictionary.GetString('shuttingdownsystem')" @change="ChangeValues('PrivateSetting', 'Locked', null, user.PrivateSetting.Locked)"></v-text-field>
              </v-col>
              <v-col cols="12">
                {{ user.PrivateSetting == undefined ? "" : user.PrivateSetting.Print.System == "0" ? Dictionary.GetString("printsystem1") : Dictionary.GetString("printsystem2") }}
                <v-spacer />
                <v-switch class="mx-2" v-model="user.PrivateSetting.Print.System" false-value="0" true-value="1" @change="ChangeValues('PrivateSetting', 'Print', 'System', user.PrivateSetting.Print.System)" inset flat :label="Dictionary.GetString('printsystem1')"></v-switch>
              </v-col>
              <v-col cols="12">
                <v-select
                  :label="Dictionary.GetString('shoptype')"
                  v-model="user.PublicSetting.Type"
                  filled
                  rounded
                  prepend-inner-icon="mdi-earth"
                  :menu-props="{ offsetY: true, closeOnClick: true }"
                  :items="[
                    { name: Dictionary.GetString('shoptype0'), value: '0' },
                    { name: Dictionary.GetString('shoptype1'), value: '1' },
                    { name: Dictionary.GetString('shoptype2'), value: '2' },
                    { name: Dictionary.GetString('shoptype3'), value: '3' },
                    { name: Dictionary.GetString('shoptype4'), value: '4' },
                  ]"
                  item-text="name"
                  item-value="value"
                  @change="ChangeValues('PublicSetting', 'Type', null, user.PublicSetting.Type)"
                ></v-select>
                <v-slider v-if="user.PublicSetting.Type == 2 || user.PublicSetting.Type == 3 || user.PublicSetting.Type == 4" class="side3 rounded-pill mb-5 pa-5 pt-10" thumb-size="50" :label="user.PublicSetting.Type == 2 || user.PublicSetting.Type == 3 || user.PublicSetting.Type == 4 ? Dictionary.GetString('managecart2') : Dictionary.GetString('managecart1')" v-model="user.PrivateSetting.Restaurant.Tables" min="0" max="100" thumb-label="always" @change="ChangeValues('PrivateSetting', 'Restaurant', 'Tables', user.PrivateSetting.Restaurant.Tables)"></v-slider>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
// JS Scripts
import GeneralClasses from "../../SubClasses/GeneralClasses.js";
import Dictionary from "../../SubClasses/Dictionary.js";
export default {
  name: "Home",
  props: ["user"],
  data() {
    return {
      GeneralClasses: GeneralClasses,
      Dictionary: Dictionary,
      LSSettings: JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())),

      NewValues: {},

      InstallValues: {
        LiekasseAT: window.atob(JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())).ATs.LiekasseAT),

        userpasswordshow: false,
        userpasswordconfirmationshow: false,

        Sections: {
          ItemsA: [],
          ItemsB: [],
          ItemsC: [],
          ItemsD: [],
          ItemsE: [],
          ItemsF: [],
          ItemsG: [],
          ItemsH: [],
          ItemsJ: [],
          Checked: [],
        },
      },
    };
  },
  watch: {
    user(Value) {
      this.user = Value;
    },
  },
  methods: {
    Create: function () {
      if (this.$refs.Create.validate()) {
        this.LSSettings.UserInfo.Sections = window.btoa(JSON.stringify(this.InstallValues.Sections.Checked));
        var UserSection = JSON.stringify(this.LSSettings.UserInfo);

        const CreateUser = new FormData();
        CreateUser.append("name", this.InstallValues.username);
        CreateUser.append("email", this.InstallValues.useremail);
        CreateUser.append("password", this.InstallValues.userpassword);
        CreateUser.append("password_confirmation", this.InstallValues.userpasswordconfirmation);
        CreateUser.append("setting", UserSection);
        this.$http.post(this.$GeneralClasses.LiekasseDomain() + window.atob(this.$GeneralClasses.API10()), CreateUser, this.$GeneralClasses.GetHeaderValue()).then((_) => {
          this.$refs.Create.reset();
          this.$toast.open({
            message: this.Dictionary.GetString("created"),
            type: "success",
            position: "bottom",
          });
        });
      }
    },
    ChangeValues: function (Setting, Attr1, Attr2, Value) {
      if (Attr2 == null) {
        this.user[Setting][Attr1] = Value;
      } else {
        this.user[Setting][Attr1][Attr2] = Value;
      }
      this.UpdateSettings();
    },
    UpdateSettings: function () {
      var PublicSettingsData = new FormData();
      PublicSettingsData.append("options", JSON.stringify(JSON.parse(JSON.stringify(this.user)).PublicSetting));

      var PrivateSettingsData = new FormData();
      PrivateSettingsData.append("options", JSON.stringify(JSON.parse(JSON.stringify(this.user)).PrivateSetting));

      this.$http
        .post(this.GeneralClasses.LiekasseDomain() + window.atob(this.GeneralClasses.API8UPublic()), PublicSettingsData, this.GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT))
        .then((PublicSettingsResult) => {
          this.$http
            .post(this.GeneralClasses.LiekasseDomain() + window.atob(this.GeneralClasses.API8UPrivate()), PrivateSettingsData, this.GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT))
            .then((PrivateSettingsResult) => {
              console.log("Done!");
            })
            .catch((Error) => console.log("Error 9 : ", Error));
        })
        .catch((Error) => console.log("Error 8 : ", Error));
    },
    SetupRoles: function () {
      for (let Item = 1; Item <= 13; Item++) {
        this.InstallValues.Sections.ItemsA.push({
          Label: this.Dictionary.GetString("userroleA" + Item),
          Value: "A" + Item,
          Checked: true,
        });
        this.InstallValues.Sections.Checked.push("A" + Item);
      }
      for (let Item = 1; Item <= 8; Item++) {
        this.InstallValues.Sections.ItemsB.push({
          Label: this.Dictionary.GetString("userroleB" + Item),
          Value: "B" + Item,
          Checked: true,
        });
        this.InstallValues.Sections.Checked.push("B" + Item);
      }
      for (let Item = 1; Item <= 5; Item++) {
        this.InstallValues.Sections.ItemsC.push({
          Label: this.Dictionary.GetString("userroleC" + Item),
          Value: "C" + Item,
          Checked: true,
        });
        this.InstallValues.Sections.Checked.push("C" + Item);
      }
      for (let Item = 1; Item <= 5; Item++) {
        this.InstallValues.Sections.ItemsD.push({
          Label: this.Dictionary.GetString("userroleD" + Item),
          Value: "D" + Item,
          Checked: true,
        });
        this.InstallValues.Sections.Checked.push("D" + Item);
      }
      for (let Item = 1; Item <= 1; Item++) {
        this.InstallValues.Sections.ItemsE.push({
          Label: this.Dictionary.GetString("userroleE" + Item),
          Value: "E" + Item,
          Checked: true,
        });
        this.InstallValues.Sections.Checked.push("E" + Item);
      }
      for (let Item = 1; Item <= 4; Item++) {
        this.InstallValues.Sections.ItemsF.push({
          Label: this.Dictionary.GetString("userroleF" + Item),
          Value: "F" + Item,
          Checked: true,
        });
        this.InstallValues.Sections.Checked.push("F" + Item);
      }
      for (let Item = 1; Item <= 5; Item++) {
        this.InstallValues.Sections.ItemsG.push({
          Label: this.Dictionary.GetString("userroleG" + Item),
          Value: "G" + Item,
          Checked: true,
        });
        this.InstallValues.Sections.Checked.push("G" + Item);
      }
      for (let Item = 1; Item <= 1; Item++) {
        this.InstallValues.Sections.ItemsH.push({
          Label: this.Dictionary.GetString("userroleH" + Item),
          Value: "H" + Item,
          Checked: true,
        });
        this.InstallValues.Sections.Checked.push("H" + Item);
      }
      for (let Item = 1; Item <= 1; Item++) {
        this.InstallValues.Sections.ItemsJ.push({
          Label: this.Dictionary.GetString("userroleJ" + Item),
          Value: "J" + Item,
          Checked: true,
        });
        this.InstallValues.Sections.Checked.push("J" + Item);
      }
    },
  },
  mounted() {
    this.SetupRoles();
  },
};
</script>
