<template>
  <v-app class="pa-0 ma-0 background OverflowHidden">
    <v-overlay absolute :opacity="0.2" :value="Cart.Overlay">
      <v-progress-circular indeterminate color="white" size="50" v-bind:class="$vuetify.rtl ? 'ml-3' : 'mr-3'"> </v-progress-circular>
    </v-overlay>
    <v-dialog v-model="CartSave.Open" width="60vw">
      <v-container class="background pa-4" fluid>
        <PrintsSavedCart v-if="CartSave.Open" :order="CartSave.item" :user="user" />
      </v-container>
    </v-dialog>
    <v-dialog v-model="Print.Open" width="80vw">
      <v-container class="background pa-7" fluid>
        <PrintsOrder v-if="Print.Open" :order="Print.Item" :user="user" :type="0" />
      </v-container>
    </v-dialog>
    <v-dialog v-model="PrinterAsBox.Open" width="80vw">
      <v-container class="background pa-7" fluid>
        <PrinterAsBox v-if="PrinterAsBox.Open" :user="user" :brutto="Cart.Items.brutto" :gived_money="Cart.Items.gived_money" :pledge="Cart.Items.pledge" :returned_money="Cart.Items.returned_money" />
      </v-container>
    </v-dialog>
    <v-dialog v-model="PrintsBonAbb.Open" width="80vw">
      <v-container class="background pa-7" fluid>
        <PrintsBonAbb v-if="PrintsBonAbb.Open" :order="Print.Item" :user="user" />
      </v-container>
    </v-dialog>
    <v-dialog v-model="InstallValues.Calc.Open" width="80vw">
      <v-container class="background pa-7" fluid>
        <CalcWithEmit v-if="InstallValues.Calc.Open" @calcclose="[(InstallValues.Calc.Open = false)]" @calcvalue="GetCalcResult" />
        <div>
          <v-switch v-if="InstallValues.Calc.Source == 'Price'" :label="$Dictionary.GetString('updateproduct')" inset v-model="Products.new_price" @calcvalue="Products.priceDB"> </v-switch>
        </div>
      </v-container>
    </v-dialog>
    <v-dialog v-model="InstallValues.Carts.Open" width="80vw">
      <v-container class="background pa-7" fluid>
        <Carts v-if="InstallValues.Carts.Open" :user="user" />
      </v-container>
    </v-dialog>
    <v-dialog v-model="InstallValues.Tags.Open" width="30vw">
      <v-container class="background pa-7" fluid>
        <v-col class="mt-0 pt-0" v-bind:class="$vuetify.rtl ? 'ml-5' : 'mr-5'">
          <v-icon large class="pt-0 mt-0" v-on:click="InstallValues.Tags.Open = false">mdi-close-circle-outline</v-icon>
        </v-col>
        <v-row justify="start" class="mt-1" no-gutters dense>
          <v-col v-for="(Tag, Index) in InstallValues.Tags.Items" :key="Index" cols="auto">
            <v-checkbox :false-value="0" :true-value="1" class="pl-6 text-capitalize" @change="[AddTag_Order_details($event, Tag.id)]" dense :label="$Dictionary.GetTheRightValue(Tag.tag_ar, Tag.tag_tr, Tag.tag_de)"></v-checkbox>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
    <v-layout style="margin-top: 50px" class="side4">
      <!-- Categories Screen Start -->
      <div v-show="!Cart.Sperated">
        <v-btn v-if="InstallValues.LSSettings.Tablet & !CategoriesDrawer" x-large style="margin-top: 40vh" v-bind:style="$vuetify.rtl ? 'right:10px;' : 'left:10px;'" fixed fab color="warning" @click="[(CategoriesDrawer = true)]">
          <v-icon>mdi-library-shelves</v-icon>
        </v-btn>
        <v-navigation-drawer :width="InstallValues.SideCategoryColumnsWidth" class="pa-2" :absolute="InstallValues.LSSettings.Tablet" :temporary="InstallValues.LSSettings.Tablet" floating v-model="CategoriesDrawer" :permanent="!InstallValues.LSSettings.Tablet" :right="$vuetify.rtl" color="side4">
          <v-layout fill-height justify-center align-start :style="'height:' + InstallValues.Height + 'px;overflow-y: auto; overflow-x: hidden'">
            <v-data-iterator item-key="id" style="width: 100%" :loading="Categories.Loading" hide-default-footer :items-per-page="Categories.Settings.RowsPerPage" :items="Categories.Items">
              <template v-slot:loading>
                <Loading
                  :settings="{
                    Loading: true,
                    Text: $Dictionary.GetString('loading'),
                    Color: 'primary',
                    margin: 'mt-15',
                  }"
                />
              </template>
              <template v-slot:no-data>
                <Loading
                  :settings="{
                    Loading: false,
                    Text: $Dictionary.GetString('noresult'),
                    Color: 'error',
                    margin: 'mt-15',
                  }"
                />
              </template>
              <template v-slot:no-results>
                <Loading
                  :settings="{
                    Loading: false,
                    Text: $Dictionary.GetString('noresult'),
                    Color: 'error',
                    margin: 'mt-15',
                  }"
                />
              </template>
              <template v-slot:default="props">
                <v-row dense justify="center">
                  <v-col cols="6">
                    <v-card elevation="1" class="rounded-md text-center mt-1" height="90%" @click="[(Products.Settings.CurrentPage = 1), (Products.Type = 'Favorite'), GetProducts()]">
                      <v-icon class="warning--text my-2" x-large>mdi-star-outline</v-icon>
                    </v-card>
                  </v-col>
                  <v-col cols="6">
                    <v-card elevation="1" class="rounded-md text-center" height="90%">
                      <v-select :items="Products.NumberOfMenus.Items" v-model="Products.NumberOfMenus.Value" rounded @change="[(Products.Settings.CurrentPage = 1), (Products.Type = 'Favorite'), GetProducts(), UpdateMenuValueInLocalStorage()]">
                        <template v-slot:selection="{ item }">
                          <div class="text-center" style="width: 100%">
                            {{ item }}
                          </div>
                        </template>
                      </v-select>
                    </v-card>
                  </v-col>
                  <v-col cols="6" v-for="item in props.items" :key="item.id">
                    <v-card height="94%" class="rounded-md OverflowHidden" elevation="1" @click="[(Products.Settings.CurrentPage = 1), (Products.Settings.CurrentPage = 1), (Products.Type = 'Category'), (Categories.ID = item.id), GetProducts()]">
                      <v-img :src="$GeneralClasses.LiekasseDomain() + '/' + item.photo" height="45" :aspect-ratio="1 / 1" class="Pointer my-0">
                        <template v-slot:placeholder>
                          <v-card height="100%" elevation="0" class="text-center py-1">
                            <v-icon large>mdi-image-off-outline</v-icon>
                          </v-card>
                        </template>
                      </v-img>
                      <v-divider />
                      <v-card-subtitle class="text-center py-2 font-weight-bold"> {{ $Dictionary.GetTheRightValue(item.name_ar, item.name_tr, item.name_de) }}</v-card-subtitle>
                    </v-card>
                  </v-col>
                </v-row>
              </template>
            </v-data-iterator>
          </v-layout>
          <v-footer width="100%" fixed height="50" class="side3 text-center">
            <v-pagination class="mx-auto" circle v-model="Categories.Settings.CurrentPage" :length="Categories.Settings.Total" :total-visible="4" next-icon="mdi-menu-right" prev-icon="mdi-menu-left" @input="GetCategories()"></v-pagination>
          </v-footer>
        </v-navigation-drawer>
      </div>
      <!-- Categories Screen End -->

      <!-- Products Screen Start -->
      <v-navigation-drawer :width="InstallValues.MainColumnsWidth" class="mx-auto pa-3 py-3 side3" floating v-model="ProductsDrawer" permanent>
        <v-layout fill-height justify-center align-start :style="'height:' + InstallValues.Height + 'px;overflow-y: auto; overflow-x: hidden'">
          <v-row align="center" justify="center" dense>
            <!--  Checkout Start -->
            <v-col cols="12">
              <v-card class="pa-1">
                <v-row justify="center" align="center" no-gutters>
                  <!--  Money Start -->
                  <v-col v-for="(Item, Index) in Money.List" :key="Index">
                    <v-card class="text-center mx-1" height="60" @click="UpdateGivedMoney()">
                      <v-card-actions>
                        <v-img :src="'/money/' + String(Item.value.replace(' ', '')).toLowerCase() + '.png'" :aspect-ratio="1 / 1" height="50" contain @click="[(Money.Result = Number(Money.Result) + Item.amount)]"></v-img>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                  <!--  Money Start -->
                </v-row>
                <v-row justify="center" align="center" dense>
                  <v-col cols="12">
                    <v-text-field append-icon="mdi-calculator" rounded suffix="€" v-model="Money.Result" @keyup.enter="UpdateGivedMoney()" @click:append="[(InstallValues.Calc.Open = true), (InstallValues.Calc.Source = 'Money')]" @click="[(InstallValues.Calc.Open = true), (InstallValues.Calc.Source = 'Money')]" prepend-inner-icon="mdi-offer" placeholder="0.00" dense filled single-line hide-details=""> </v-text-field>
                  </v-col>

                  <v-col cols="6" class="px-4 py-5">
                    <v-checkbox v-model="Cart.NoPrint" :true-value="1" :false-value="0" hide-details="" dense :label="$Dictionary.GetString('noprint')"></v-checkbox>
                  </v-col>
                  <v-col cols="6" class="px-4">
                    <v-checkbox v-show="InstallValues.Sperated.length > 0" v-if="user.PublicSetting.Type == 2" v-model="Cart.Sperated" :disabled="Cart.Sperated" hide-details="" dense :label="$Dictionary.GetString('speratedpay')"></v-checkbox>
                  </v-col>

                  <v-col cols="4" class="text-center">
                    <v-btn block :disabled="Cart.Items.order_detals == null || Cart.Items.order_detals.length == 0 ? true : Number(Cart.Items.gived_money) < (Number(Cart.Items.brutto) + Number(Cart.Items.pledge)).toFixed(2)" @click="MakeOrder(true)" class="success rounded-lg" height="35">
                      <v-card color="transparent" flat class="white--text">
                        <h3>{{ $Dictionary.GetTheRightPaymentMethod(1) }}</h3>
                      </v-card>
                    </v-btn>
                  </v-col>
                  <v-col cols="4" class="text-center">
                    <v-btn block :disabled="Cart.Items.order_detals == null || Cart.Items.order_detals.length == 0" @click="MakeOrder(false)" class="info rounded-lg" height="35">
                      <v-card color="transparent" flat class="white--text">
                        <h3>{{ $Dictionary.GetTheRightPaymentMethod(2) }}</h3>
                      </v-card>
                    </v-btn>
                  </v-col>
                  <v-col cols="4" class="text-center">
                    <v-btn block class="error rounded-lg px-3" @click="CancelOrder()" height="35">
                      <h3>{{ $Dictionary.GetString("cancelorder") }}</h3>
                    </v-btn>
                  </v-col>
                  <v-col cols="6" class="error--text px-2 py-4">
                    <v-card-actions class="ZeroSpace font-weight-bold">
                      {{ $Dictionary.GetString("receivedmoney") }}
                      <v-spacer />
                      {{ Cart.Items.order_detals == null || Cart.Items.order_detals.length == 0 ? Number(0).toFixed(2) : Number(Cart.Items.gived_money).toFixed(2) }}€
                    </v-card-actions>
                  </v-col>
                  <v-col cols="6" class="info--text px-2">
                    <v-card-actions class="ZeroSpace font-weight-bold">
                      {{ $Dictionary.GetString("customertotal") }}
                      <v-spacer />
                      {{ Cart.Items.order_detals == null || Cart.Items.order_detals.length == 0 ? Number(0).toFixed(2) : Number(Cart.Items.returned_money).toFixed(2) }}€
                    </v-card-actions>
                  </v-col>
                  <v-col v-show="Cart.Sperated" cols="12" class="text-center">
                    <v-card-actions class="secondary white--text rounded-lg pa-2 ZeroSpace font-weight-bold">
                      {{ $Dictionary.GetString("boxtotal") }}
                      <v-spacer />
                      {{ Cart.Items.order_detals == null || Cart.Items.order_detals.length == 0 ? Number(0).toFixed(2) : (Number(Cart.Items.brutto) + Number(Cart.Items.pledge)).toFixed(2) }}€
                    </v-card-actions>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <!--  Checkout End -->

            <v-col cols="12" v-show="!Cart.Sperated">
              <v-data-iterator item-key="id" style="width: 100%" must-sort :loading="Products.Loading" hide-default-footer :items-per-page="Products.Settings.RowsPerPage" :items="Products.Items" :sort-by="InstallValues.LSSettings.SortProduct.Check">
                <template v-slot:loading>
                  <Loading
                    :settings="{
                      Loading: true,
                      Text: $Dictionary.GetString('loading'),
                      Color: 'primary',
                      margin: 'mt-15',
                    }"
                  />
                </template>
                <template v-slot:no-data>
                  <Loading
                    :settings="{
                      Loading: false,
                      Text: $Dictionary.GetString('noresult'),
                      Color: 'error',
                      margin: 'mt-15',
                    }"
                  />
                </template>
                <template v-slot:no-results>
                  <Loading
                    :settings="{
                      Loading: false,
                      Text: $Dictionary.GetString('noresult'),
                      Color: 'error',
                      margin: 'mt-15',
                    }"
                  />
                </template>
                <template v-slot:header>
                  <v-row dense>
                    <v-col cols="6" v-if="$GeneralClasses.Check('A5')" class="px-1 py-1">
                      <v-form autocomplete="off" @submit.prevent>
                        <v-text-field class="rounded-md" clearable append-icon="mdi-calculator" filled solo single-line hide-details="" @keyup.enter="AddToCartByBarcode()" @click:append="[(InstallValues.Calc.Open = true), (InstallValues.Calc.Source = 'Barcode')]" prepend-inner-icon="mdi-barcode-scan" :label="$Dictionary.GetString('barcode')" ref="Barcode" v-model="Products.Barcode"></v-text-field>
                      </v-form>
                    </v-col>
                    <v-col :cols="$GeneralClasses.Check('A5') ? 6 : 12" class="px-1 py-1">
                      <v-form autocomplete="off" @submit.prevent>
                        <v-autocomplete class="rounded-md" clearable :search-input.sync="Products.Search.Sync" :items="Products.Search.Items" :item-value="$Dictionary.GetTheRightValue('name_ar', 'name_tr', 'name_de')" :item-text="$Dictionary.GetTheRightValue('name_ar', 'name_tr', 'name_de')" filled solo single-line hide-details="" prepend-inner-icon="mdi-search-web" :label="$Dictionary.GetString('title')" :no-data-text="$Dictionary.GetString('noresult')" :loading="Products.Search.Loading" v-model="Products.Search.Value"></v-autocomplete>
                      </v-form>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:default="props">
                  <v-row dense align-content="stretch" justify="center">
                    <!-- Product Item Start -->
                    <!-- Documentation https://vuetifyjs.com/en/components/grids/#usage -->
                    <v-col cols="12" md="3" v-for="item in props.items" :key="item.id">
                      <v-card height="100%" class="rounded-md text-center" elevation="1" width="100%">
                        <v-card flat @click="user.PrivateSetting.separate_add == 1 ? AddToCart(item.id, true) : AddToCart(item.id, false)" class="Pointer" width="100%">
                          <v-img :src="$GeneralClasses.LiekasseDomain() + '/' + item.photo" width="100%" height="50" contain :aspect-ratio="1 / 1" class="Pointer">
                            <template v-slot:placeholder>
                              <v-card height="100%" elevation="0" class="text-center py-2">
                                <v-icon large>mdi-image-off-outline</v-icon>
                              </v-card>
                            </template>
                          </v-img>
                          <v-divider />
                          <v-card-subtitle class="font-weight-bold text-center py-1"> {{ $Dictionary.GetTheRightValue(item.name_ar, item.name_tr, item.name_de) }}</v-card-subtitle>
                        </v-card>
                        <v-btn height="30" tile width="100%" color="primary" @click="AddToCart(item.id, true)">
                          <v-icon size="22"> mdi-plus-circle-outline </v-icon>
                        </v-btn>
                      </v-card>
                    </v-col>
                    <!-- Product Item End -->
                  </v-row>
                </template>
              </v-data-iterator>
            </v-col>
          </v-row>
        </v-layout>
        <v-footer v-show="!Cart.Sperated" :width="InstallValues.MainColumnsWidth" fixed height="50" class="side3 text-center">
          <v-row justify="center" align="center">
            <v-col cols="12">
              <v-pagination circle v-model="Products.Settings.CurrentPage" :length="Products.Settings.Total" :total-visible="12" next-icon="mdi-menu-right" prev-icon="mdi-menu-left" @input="GetProducts()"> </v-pagination>
            </v-col>
          </v-row>
        </v-footer>
      </v-navigation-drawer>
      <!-- Products Screen End -->

      <!-- Cart Screen Start -->
      <div v-show="!Cart.Sperated">
        <v-btn v-if="InstallValues.LSSettings.Tablet & !CartDrawer" x-large style="margin-top: 40vh" v-bind:style="$vuetify.rtl ? 'left:10px;' : 'right:10px;'" fixed fab color="success" @click="[(CartDrawer = true)]">{{ Cart.Items.order_detals == null || Cart.Items.order_detals.length == 0 ? Number(0).toFixed(2) : (Number(Cart.Items.brutto) + Number(Cart.Items.pledge)).toFixed(2) }}€</v-btn>
        <v-navigation-drawer :width="InstallValues.SideColumnsWidth" class="pa-2" :absolute="InstallValues.LSSettings.Tablet" :temporary="InstallValues.LSSettings.Tablet" floating v-model="CartDrawer" :permanent="!InstallValues.LSSettings.Tablet" :right="!$vuetify.rtl" color="side4">
          <v-layout fill-height justify-center align-start :style="'height:' + InstallValues.Height + 'px;overflow-y: auto; overflow-x: hidden'">
            <v-row dense>
              <v-col cols="12" v-if="!Cart.Loading">
                <v-card elevation="1" color="secondary" class="rounded-md white--text">
                  <v-card-actions class="headline my-auto" style="height: 40px">
                    <span>{{ Cart.Items.order_detals == null || Cart.Items.order_detals.length == 0 ? Number(0).toFixed(2) : (Number(Cart.Items.brutto) + Number(Cart.Items.pledge)).toFixed(2) }}€</span>
                    <v-spacer />
                    <v-btn :disabled="Cart.Items.order_detals == null || Cart.Items.order_detals.length == 0" @click="UpdateTable()" v-if="user.PublicSetting.Type == 2" x-small class="rounded-md px-2" height="30" color="warning" dark> <v-icon>mdi-food</v-icon>&nbsp;:&nbsp;{{ Cart.Items.table_nr }} </v-btn>
                    <v-btn :disabled="Cart.Items.order_detals == null || Cart.Items.order_detals.length == 0" @click="UpdateNotes()" v-if="user.PublicSetting.Type == 2" x-small class="rounded-md px-2" height="30" color="info" dark>
                      <v-icon>mdi-pen-remove</v-icon>
                    </v-btn>
                  </v-card-actions>
                  <v-card-actions class="ZeroSpace white pa-0">
                    <v-checkbox style="padding-right: 14px" :disabled="Cart.Items.order_detals == null || Cart.Items.order_detals.length == 0" v-show="user.PublicSetting.Type == 2" v-model="Cart.Items.place" :false-value="0" :true-value="1" :label="$Dictionary.GetString('dinein')" @change="UpdatePlace()" dense hide-details=""> </v-checkbox>
                  </v-card-actions>
                </v-card>
              </v-col>

              <v-col cols="12">
                <v-data-iterator item-key="id" style="width: 100%" must-sort :loading="Cart.Loading" hide-default-footer :items="Cart.Items.order_detals" :items-per-page="500">
                  <template v-slot:loading>
                    <Loading
                      :settings="{
                        Loading: true,
                        Text: $Dictionary.GetString('loading'),
                        Color: 'primary',
                        margin: 'mt-15',
                      }"
                    />
                  </template>
                  <template v-slot:no-data>
                    <Loading
                      :settings="{
                        Loading: false,
                        Text: $Dictionary.GetString('noresult'),
                        Color: 'error',
                        margin: 'mt-15',
                      }"
                    />
                  </template>
                  <template v-slot:no-results>
                    <Loading
                      :settings="{
                        Loading: false,
                        Text: $Dictionary.GetString('noresult'),
                        Color: 'error',
                        margin: 'mt-15',
                      }"
                    />
                  </template>

                  <template v-slot:default="props">
                    <v-row dense align-content="stretch" justify="center">
                      <v-col cols="12" v-for="item in props.items" :key="item.id">
                        <v-card height="100%" class="pl-0 pr-0 rounded-md OverflowHidden" elevation="1">
                          <v-card-actions class="font-weight-bold FontColor--text justify-end headline ZeroSpace mt-2 px-2">
                            {{ (Number(item.pledge) + Number(item.total_price)).toFixed(2) }}
                            €
                          </v-card-actions>

                          <v-card-actions class="font-weight-bold FontColor--text justify-start headline ZeroSpace mt-n6 mb-n3 px-2">
                            <v-checkbox :disabled="user.PublicSetting.Type == 0 || user.PublicSetting.Type == 1 || user.PublicSetting.Type == 3 || user.PublicSetting.Type == 4" v-model="item.checked" :label="$Dictionary.GetTheRightValue(item.product_name_ar, item.product_name_tr, item.product_name_de)" :false-value="0" :true-value="1" :value="item.id" @change="UpdateCheckedOrderDetails($event, item)" />
                          </v-card-actions>
                          <v-chip v-show="user.PublicSetting.Type == 2" @click="[DeleteTag_Order_details(item.id, Tag.id)]" v-for="(Tag, index) in item.tags" :key="index" class="ml-3 mb-2">
                            {{ $Dictionary.GetTheRightValue(Tag.tag_ar, Tag.tag_tr, Tag.tag_de) }}
                          </v-chip>

                          <v-divider />
                          <v-card-actions v-if="item.pledge > 0">
                            <span class="font-weight-bold">{{ $Dictionary.GetString("pledge") }}</span>
                            <v-spacer />{{ Number(item.pledge).toFixed(2) }}€
                          </v-card-actions>
                          <v-card-actions>
                            <v-text-field class="font-weight-bold" v-if="$GeneralClasses.Check('A13')" v-model="item.offer_price" readonly @click:append="[(InstallValues.Calc.Open = true), (InstallValues.Calc.Source = 'Price'), (InstallValues.Calc.Item = item)]" @click="[(InstallValues.Calc.Open = true), (InstallValues.Calc.Source = 'Price'), (InstallValues.Calc.Item = item)]" append-icon="mdi-calculator" suffix="€" dense hide-details="" rounded filled single-line> </v-text-field>
                            <v-spacer class="ml-1" />
                            <v-text-field v-model="item.QTY" readonly class="font-weight-bold" @click:append="[(InstallValues.Calc.Open = true), (InstallValues.Calc.Source = 'QTY'), (InstallValues.Calc.Item = item)]" @click="[(InstallValues.Calc.Open = true), (InstallValues.Calc.Source = 'QTY'), (InstallValues.Calc.Item = item)]" append-icon="mdi-calculator" :suffix="$Dictionary.GetTheRightWeight(item.unit)" :disabled="$GeneralClasses.Check('P1') && item.printed == 1" hide-details="" rounded dense filled single-line> </v-text-field>
                          </v-card-actions>
                          <v-card-actions>
                            <!--  UpdateNotesDetailes Start -->
                            <v-btn small @click="UpdateNotesDetailes(item)" elevation="0" height="30" color="info" dark rounded :disabled="Cart.Items.order_detals == null || Cart.Items.order_detals.length == 0" v-if="user.PublicSetting.Type == 2">
                              <v-icon>mdi-pen-remove</v-icon>
                            </v-btn>
                            <!--  Manage  Tag -->
                            <v-btn v-if="user.PublicSetting.Type == 2" small v @click="[GetTags(item.product_id), (InstallValues.Tags.OrderDetailsId = item.id), (InstallValues.Tags.ProductID = item.product_id)]" elevation="0" height="30" color="success" rounded dark>
                              <v-icon> mdi-plus-circle-outline </v-icon>
                            </v-btn>
                            <v-btn v-show="$GeneralClasses.Check('A10')" :disabled="$GeneralClasses.Check('P1') && item.printed == 1" small @click="DeleteFromCart(item.id)" elevation="0" height="30" color="error" rounded>
                              <v-icon> mdi-delete-outline </v-icon>
                            </v-btn>
                            <v-card-actions v-show="user.PublicSetting.Type == 2">
                              <v-menu offset-y>
                                <template #activator="{ on, attrs }">
                                  <v-btn v-bind="attrs" v-on="on" class="rounded-lg" depressed> {{ $Dictionary.GetTheRightWeight(item.unit) }} </v-btn>
                                </template>
                                <v-card elevation="0" v-if="user.PublicSetting.Type == 2">
                                  <v-btn height="40" elevation="0" v-for="(Count, index) in item.QTY" :key="index" @click="item.QTYInpute = Count" class="ml-2 rounded-lg" color="white">
                                    {{ Count }}
                                  </v-btn>
                                </v-card>
                              </v-menu>
                            </v-card-actions>
                          </v-card-actions>

                          <v-card-actions v-if="item.notes != null">
                            <v-chip class="Block" v-if="user.PublicSetting.Type == 2">
                              <v-icon left>mdi-pen-remove</v-icon>
                              {{ item.notes }}
                            </v-chip>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-iterator>
              </v-col>
            </v-row>
          </v-layout>
          <v-footer width="100%" fixed height="60" class="side3">
            <v-row justify="center" no-gutters>
              <v-tooltip key="1" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn :disabled="Cart.Items.order_detals == null || Cart.Items.order_detals.length == 0" v-bind="attrs" width="15%" v-on="on" @click="[SaveCart()]" height="45" small class="mx-1 rounded-lg" elevation="0" color="warning">
                    <v-icon> mdi-content-save-all-outline </v-icon>
                  </v-btn>
                </template>
                <span> <v-icon dark> mdi-information-outline </v-icon>&nbsp;{{ $Dictionary.GetString("save") }} </span>
              </v-tooltip>
              <v-tooltip key="2" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" width="15%" v-on="on" @click="[(InstallValues.Carts.Open = true)]" height="45" small class="mx-1 rounded-lg" elevation="0" color="info">
                    <v-icon> mdi-bookshelf </v-icon>
                  </v-btn>
                </template>
                <span> <v-icon dark> mdi-information-outline </v-icon>&nbsp;{{ $Dictionary.GetString("load") }} </span>
              </v-tooltip>
            </v-row>
          </v-footer>
        </v-navigation-drawer>
      </div>

      <!-- Cart Screen End -->
    </v-layout>
  </v-app>
</template>
<script>
import CalcWithEmit from "../../Shared/CalcWithEmit.vue";
import Loading from "../../Shared/Loading.vue";
import Carts from "../Carts.vue";
import PrintsOrder from "../../Shared/Prints/PrintsOrder.vue";
import PrinterAsBox from "../../Shared/Prints/PrinterAsBox.vue";
import PrintsBonAbb from "../../Shared/Prints/PrintsBonAbb.vue";
import PrintsSavedCart from "../../Shared/Prints/PrintsSavedCart.vue";
export default {
  props: ["user", "gived_money", "brutto", "pledge", "returned_money", "order"],
  name: "Normal",
  components: {
    Loading,
    CalcWithEmit,
    Carts,
    PrintsOrder,
    PrinterAsBox,
    PrintsBonAbb,
    PrintsSavedCart,
  },
  data() {
    return {
      InstallValues: {
        LiekasseAT: window.atob(JSON.parse(localStorage.getItem(this.$GeneralClasses.LSSettings())).ATs.LiekasseAT),
        LSSettings: JSON.parse(localStorage.getItem(this.$GeneralClasses.LSSettings())),
        Height: window.innerHeight - 110,
        MainColumnsWidth: JSON.parse(localStorage.getItem(this.$GeneralClasses.LSSettings())).Tablet ? "95vw" : "65vw",
        SideColumnsWidth: JSON.parse(localStorage.getItem(this.$GeneralClasses.LSSettings())).Tablet ? "90vw" : "22vw",
        SideCategoryColumnsWidth: JSON.parse(localStorage.getItem(this.$GeneralClasses.LSSettings())).Tablet ? "90vw" : "16vw",
        Calc: {
          Open: false,
          Result: 0,
          Source: "",
          Item: {},
        },
        OrderDetailsID: [],
        QTYSeparate: [],

        Sperated: [],

        Carts: {
          Open: false,
        },
        Tags: {
          Open: false,
          ProductID: null,
          Items: [],
          TagID: null,
          OrderDetailsId: null,
        },
      },

      Categories: {
        ID: 0,
        Loading: true,
        Items: [],
        Settings: {
          RowsPerPage: 14,
          CurrentPage: 1,
          Total: 0,
        },
      },
      Products: {
        Type: "Favorite",

        Barcode: "",
        Search: {
          Value: "",
          Sync: "",
          Items: [],
          Loading: false,
        },
        Loading: true,
        Items: [],
        Settings: {
          RowsPerPage: 8,
          CurrentPage: 1,
          Total: 0,
        },
        NumberOfMenus: {
          Items: [],
          Value: 0,
        },
        new_price: false,
        priceDB: "",
        ProductDetails: {},
      },
      Cart: {
        CartNR: JSON.parse(localStorage.getItem(this.$GeneralClasses.LSSettings())).POSSystem.CartNR,
        Loading: true,
        Items: {},
        Sperated: false,
        NoPrint: "",
        Overlay: false,
      },

      Money: {
        List: [],
        Result: 0,
      },

      Print: {
        Open: false,
        Item: 0,
      },

      PrinterAsBox: {
        Open: false,
      },
      PrintsBonAbb: {
        Open: false,
      },

      CategoriesDrawer: false,
      ProductsDrawer: true,
      CartDrawer: false,
      CartSave: {
        Open: false,
        item: 0,
      },
      QtyDialog: {
        Open: false,
        QTyCount: [],
      },
    };
  },
  created() {
    this.Products.NumberOfMenus.Value = this.InstallValues.LSSettings.POSSystem.MenuValue;
  },
  watch: {
    user(Value) {
      this.user = Value;
    },

    "Cart.Loading"(Value) {
      this.Cart.Overlay = Value;
    },
    "Print.Open"(Value) {
      if (!Value) {
        window.location.reload();
      }
    },
    "PrinterAsBox.Open"(Value) {
      if (!Value) {
        window.location.reload();
      }
    },
    "PrintsBonAbb.Open"(Value) {
      if (!Value) {
        window.location.reload();
      }
    },
    "Cart.Sperated"(Value) {
      if (Value) {
        this.SperatedOrder();
      }
    },
    "Cart.Items"(newValue, oldValue) {
      if (oldValue != newValue) {
        this.InstallValues.LSSettings = JSON.parse(localStorage.getItem(this.$GeneralClasses.LSSettings()));
        this.InstallValues.LSSettings.POSSystem.UserScreenCounter++;
        localStorage.setItem(this.$GeneralClasses.LSSettings(), JSON.stringify(this.InstallValues.LSSettings));
      }
    },
    "InstallValues.Carts.Open"(Value) {
      if (!Value) {
        window.location.reload();
      }
    },
    "Products.Search.Value"() {
      this.Products.Type = "Search";
      this.GetProducts();
    },
    "Products.Search.Sync"(Value) {
      if (Value != null) {
        this.Products.Search.Loading = true;
        this.$http
          .get(this.$GeneralClasses.LiekasseDomain() + window.atob(this.$GeneralClasses.API6()) + Value, this.$GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT))
          .then((Result) => {
            // console.log(Result);
            this.Products.Search.Items = Result.data.data;
            this.Products.Search.Loading = false;
          })
          .catch((Error) => {
            // console.log(Error);
            this.Products.Search.Items = [];
            this.Products.Search.Loading = false;
          });
      }
    },
  },
  methods: {
    GetCategories() {
      this.Categories.Loading = true;
      this.Categories.Items = [];
      this.$http
        .get(this.$GeneralClasses.LiekasseDomain() + window.atob(this.$GeneralClasses.API1()) + "?page=" + this.Categories.Settings.CurrentPage, this.$GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT, this.Categories.Settings.RowsPerPage))
        .then((Result) => {
          // console.log(Result);
          this.Categories.Items = Result.data.data;
          this.Categories.Settings.Total = Result.data.meta.last_page;
          this.Categories.Loading = false;
          this.ResetFocus();
        })
        .catch((Error) => {
          // console.log(Error);
          this.Categories.Items = [];
          this.Categories.Settings.Total = 0;
          this.Categories.Loading = false;
        });
    },
    GetProducts() {
      this.Products.Loading = true;
      this.Products.Items = [];

      var Source = "";
      var HeaderSettings = "";
      var Pagination = "?page=" + this.Products.Settings.CurrentPage;

      switch (this.Products.Type) {
        case "Favorite":
          Source = window.atob(this.$GeneralClasses.API5()) + Pagination;
          HeaderSettings = this.$GeneralClasses.GetHeaderValueWithGroup(this.InstallValues.LiekasseAT, this.Products.Settings.RowsPerPage, this.Products.NumberOfMenus.Value);
          break;
        case "Category":
          Source = window.atob(this.$GeneralClasses.API4()) + this.Categories.ID + Pagination;
          HeaderSettings = this.$GeneralClasses.GetHeaderValueWithGroup(this.InstallValues.LiekasseAT, this.Products.Settings.RowsPerPage, this.Products.NumberOfMenus.Value);
          break;
        case "Search":
          Source = window.atob(this.$GeneralClasses.API6()) + this.Products.Search.Value + Pagination;
          HeaderSettings = this.$GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT, this.Products.Settings.RowsPerPage);
          break;
        case "Barcode":
          Source = window.atob(this.$GeneralClasses.API3()) + this.Products.Barcode;
          HeaderSettings = this.$GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT, this.Products.Settings.RowsPerPage);
          break;
        default:
          break;
      }
      this.$http
        .get(this.$GeneralClasses.LiekasseDomain() + Source, HeaderSettings)
        .then((Result) => {
          this.Products.Items = Result.data.data;
          // console.log(Result.data.data);
          this.Products.Settings.Total = Result.data.meta.last_page;
          this.Products.Loading = false;
          this.Products.Search.Sync = "";
        })
        .catch((Error) => {
          // console.log(Error);
          this.Products.Items = [];
          this.Products.Settings.Total = 0;
          this.Products.Loading = false;
        });
    },
    CartInfosUpdate(Data, Reset) {
      this.Cart.Items = Data;
      // console.log(this.Cart.Items);
      this.Cart.Loading = false;
      if (Reset) {
        this.ResetFocus();
      }
    },
    GetCart() {
      if (this.Cart.CartNR != 0) {
        const Data = new FormData();
        Data.append("cart_nr", this.Cart.CartNR);
        this.Cart.Loading = true;
        this.$http
          .post(this.$GeneralClasses.LiekasseDomain() + window.atob(this.$GeneralClasses.APIPOSSystem0()), Data, this.$GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT, this.Categories.Settings.RowsPerPage))
          .then((Result) => {
            if (this.user.PublicSetting.Type == 2) {
              for (var i = 0; i < Result.data.data.order_detals.length; i++) {
                Result.data.data.order_detals[i].QTYInpute = 1;
              }
            }
            this.CartInfosUpdate(Result.data.data, true);
            this.CartSave.item = Result.data.data.id;
            this.LoadMoney();
          })
          .catch((Error) => {
            // console.log(Error);
            this.Cart.Loading = false;
          });
      } else {
        this.Cart.Items.brutto = 0;
        this.Cart.Items.table_nr = 0;
        this.Cart.Loading = false;
      }
    },

    AddToCart(ID, Sperated) {
      this.Cart.Loading = true;
      const Data = new FormData();
      Data.append("cart_nr", this.Cart.CartNR);
      Data.append("product_id", ID);
      var Source = Sperated ? window.atob(this.$GeneralClasses.APIPOSSystem2()) : window.atob(this.$GeneralClasses.APIPOSSystem1());
      this.$http
        .post(this.$GeneralClasses.LiekasseDomain() + Source, Data, this.$GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT))
        .then((Result) => {
          if (this.user.PublicSetting.Type == 2) {
            for (var i = 0; i < Result.data.data.order_detals.length; i++) {
              Result.data.data.order_detals[i].QTYInpute = 1;
            }
          }

          this.CartInfosUpdate(Result.data.data, true);
          this.UpdateLocalStorage(false, Result.data.data.cart_nr);
          this.CartSave.item = Result.data.data.id;
        })
        .catch((Error) => {
          // console.log(Error);
          this.Cart.Loading = false;
        });
    },
    AddToCartByBarcode() {
      this.Cart.Loading = true;
      const Data = new FormData();
      Data.append("cart_nr", this.Cart.CartNR);
      Data.append("barcode", this.Products.Barcode);
      var Source = window.atob(this.$GeneralClasses.APIPOSSystem23());
      this.$http
        .post(this.$GeneralClasses.LiekasseDomain() + Source, Data, this.$GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT))
        .then((Result) => {
          this.Products.Barcode = "";
          // console.log(Result);
          this.CartInfosUpdate(Result.data.data, true);
          this.UpdateLocalStorage(false, Result.data.data.cart_nr);
        })
        .catch((Error) => {
          // console.log(Error);
          this.Cart.Loading = false;
          this.$toast.open({
            message: this.$Dictionary.GetString("Productnotfound").toUpperCase(),
            type: "error",
            position: "bottom",
            duration: 1000,
          });
          this.Products.Barcode = "";
        });
      this.ResetFocus();
    },
    DeleteFromCart(ID) {
      this.$GeneralClasses.ConfirmAlert(this.$Dictionary.GetString("Do you want to delete the product")).then((Result) => {
        if (Result) {
          this.Cart.Loading = true;
          const Data = new FormData();
          Data.append("order_details_id", ID);
          var Source = window.atob(this.$GeneralClasses.APIPOSSystem3());
          this.$http
            .post(this.$GeneralClasses.LiekasseDomain() + Source, Data, this.$GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT))
            .then((Result) => {
              // console.log(Result);
              this.CartInfosUpdate(Result.data.data, true);
              this.UpdateLocalStorage(false, Result.data.data.cart_nr);
            })
            .catch((Error) => {
              // console.log(Error);
              this.Cart.Loading = false;
            });
        }
      });
    },
    Update_PriceDB(Value) {
      // console.log(this.InstallValues.Calc.Item.product_id);
      if (this.Products.new_price) {
        console.log(this.Products.new_price);
        const Data = new FormData();
        Data.append("id", this.InstallValues.Calc.Item.product_id);
        Data.append("offer_price", Value);
        this.$http
          .post(this.$GeneralClasses.LiekasseDomain() + window.atob(this.$GeneralClasses.APIPOSSystem25()), Data, this.$GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT))
          .then((Result) => {
            console.log(Result);
          })
          .catch((Error) => {
            console.log(Error);
          });
      }
      this.Products.new_price = false;
      // console.log(this.Products.new_price);
    },
    UpdateCart(Type, Value) {
      var Source = "";
      this.Cart.Loading = true;
      const Data = new FormData();
      Data.append("cart_nr", this.Cart.CartNR);
      Data.append("order_details_id", this.InstallValues.Calc.Item.id);
      if (Type == "Price") {
        Data.append("price", Value);
        Source = window.atob(this.$GeneralClasses.APIPOSSystem5());
      } else if (Type == "QTY") {
        Data.append("QTY", Value);
        Source = window.atob(this.$GeneralClasses.APIPOSSystem6());
      }
      this.$http
        .post(this.$GeneralClasses.LiekasseDomain() + Source, Data, this.$GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT))
        .then((Result) => {
          this.Products.priceDB = Result.data.data.order_detals[0].offer_price;
          this.Update_PriceDB(this.Products.priceDB);
          this.CartInfosUpdate(Result.data.data, true);
          this.UpdateLocalStorage(false, Result.data.data.cart_nr);
        })
        .catch((Error) => {
          // console.log(Error);
          this.Cart.Loading = false;
        });
    },

    SperatedOrder() {
      this.Cart.Loading = true;
      const Data = new FormData();
      this.InstallValues.Sperated.forEach((product, index) => {
        Data.append("order_details_id[" + index + "]", product.ID);
        Data.append("QTY[" + index + "]", product.QTYInpute);
      });

      Data.append("cart_nr", this.Cart.Items.cart_nr);
      var Source = window.atob(this.$GeneralClasses.APIPOSSystem19());
      this.$http
        .post(this.$GeneralClasses.LiekasseDomain() + Source, Data, this.$GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT))
        .then((Result) => {
          // console.log(Result);
          this.CartInfosUpdate(Result.data.data, true);
        })
        .catch((Error) => {
          // console.log(Error);
          this.Cart.Loading = false;
        });
    },

    CancelOrder() {
      if (this.Cart.Items.id !== undefined) {
        this.$GeneralClasses.ConfirmAlert(this.$Dictionary.GetString("Do you want to cancel the bill")).then((Result) => {
          if (Result) {
            this.Cart.Loading = true;
            const Data = new FormData();
            Data.append("parent_order", this.Cart.Items.parent_order);
            Data.append("order_id", this.Cart.Items.id);
            var Source = this.Cart.Sperated ? window.atob(this.$GeneralClasses.APIPOSSystem21()) : window.atob(this.$GeneralClasses.APIPOSSystem20());
            this.$http
              .post(this.$GeneralClasses.LiekasseDomain() + Source, Data, this.$GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT))
              .then((Result) => {
                if (Result != null) {
                  this.Print.Item = Result.data.data.id;
                  this.PrintsBonAbb.Open = true;
                }
                // console.log(Result);
                // this.Cart.Loading = true;
                if (this.Cart.Sperated) {
                  this.UpdateLocalStorage(true, Result.data.data.cart_nr);
                } else {
                  this.UpdateLocalStorage(false, 0);
                }
              })
              .catch((Error) => {
                console.log(Error);
                this.Cart.Loading = false;
              });
          }
        });
      }
    },

    MakeOrder(Cash) {
      this.Cart.Loading = true;
      const Data = new FormData();
      Data.append("order_id", this.Cart.Items.id);
      var Source = Cash ? window.atob(this.$GeneralClasses.APIPOSSystem8()) : window.atob(this.$GeneralClasses.APIPOSSystem9());
      this.$http
        .post(this.$GeneralClasses.LiekasseDomain() + Source, Data, this.$GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT))
        .then((Result) => {
          console.log(Result);
          this.Cart.Loading = false;
          if (this.user.PrivateSetting.print_node_invoice == 0) {
            if (this.Cart.NoPrint) {
              this.PrinterAsBox.Open = true;
            } else {
              this.Print.Item = Result.data.data.id;
              this.Print.Open = true;
            }

            this.Cart.Loading = false;
          } else {
            this.Cart.Loading = true;
          }
        })
        .then(() => {
          try {
            if (this.Cart.Sperated) {
              // Do Nothing & DON"T Reset Local Storage
            } else {
              // Reset Local Storage and DON"T Reload Page
              this.UpdateLocalStorage(false, 0);
            }
          } finally {
            if (this.user.PrivateSetting.print_node_invoice == 1) {
              window.location.reload();
            }
          }
        })
        .catch((Error) => {
          
          this.$toast.open({
            message: Error.message.includes(500) ? "TSE has an error in auth " : Error.message ,
            type: "error",
            position: "bottom",
          });

          this.Cart.Loading = false;

        });
    },
    DecreaseQtyOrderDetatils(OrderDetatilsID) {
      console.log(this.QtyDialog.QTyCount.length);
      console.log(OrderDetatilsID);
    },

    SaveCart() {
      this.Cart.Loading = true;
      const Data = new FormData();
      Data.append("cart_nr", this.Cart.CartNR);
      var Source = window.atob(this.$GeneralClasses.APIPOSSystem11());
      this.$http
        .post(this.$GeneralClasses.LiekasseDomain() + Source, Data, this.$GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT))
        .then((Result) => {
          this.CartInfosUpdate(Result.data.data, false);
          // console.log(this.user.PrivateSetting.PrintProductsWithoutInvoice);
          if (this.user.PrivateSetting.PrintProductsWithoutInvoice == 1) {
            this.CartSave.Open = true;
            this.UpdateLocalStorage(false, 0);
          } else {
            this.UpdateLocalStorage(true, 0);
          }
        })
        .catch((Error) => {
          this.$toast.open({
            message: String("Error Saving Cart .. Try Again").toUpperCase(),
            type: "error",
            position: "bottom",
          });
          this.Cart.Loading = false;
        });
    },

    UpdatePlace() {
      this.Cart.Loading = true;
      const Data = new FormData();
      Data.append("cart_nr", this.Cart.CartNR);
      Data.append("place", this.Cart.Items.place);
      var Source = window.atob(this.$GeneralClasses.APIPOSSystem10());
      this.$http
        .post(this.$GeneralClasses.LiekasseDomain() + Source, Data, this.$GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT))
        .then((Result) => {
          // console.log(Result);
          Result.data.data.place = Number(Result.data.data.place);
          this.CartInfosUpdate(Result.data.data, true);
          this.UpdateLocalStorage(false, Result.data.data.cart_nr);
        })
        .catch((Error) => {
          // console.log(Error);
          this.Cart.Loading = false;
        });
    },

    UpdateCheckedOrderDetails(event, item) {
      if (item.QTYSeparate != "") {
        if (event == 1) {
          const sperated = new Object();
          sperated.ID = item.id;
          sperated.QTYInpute = Number(item.QTYInpute);
          this.InstallValues.Sperated.push(sperated);
        } else {
          const foundedID = this.InstallValues.Sperated.findIndex((x) => x.ID == item.id);

          this.InstallValues.Sperated.splice(foundedID, 1);
        }
      }
      console.log(this.InstallValues.Sperated);
    },

    UpdateTable() {
      this.$GeneralClasses.InputAlert().then((Result) => {
        if (Result) {
          this.Cart.Loading = true;
          const Data = new FormData();
          Data.append("cart_nr", this.Cart.CartNR);
          Data.append("table_nr", Result);
          var Source = window.atob(this.$GeneralClasses.APIPOSSystem4());
          this.$http
            .post(this.$GeneralClasses.LiekasseDomain() + Source, Data, this.$GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT))
            .then((Result) => {
              // console.log(Result);
              this.CartInfosUpdate(Result.data.data, true);
              this.UpdateLocalStorage(false, Result.data.data.cart_nr);
            })
            .catch((Error) => {
              // console.log(Error);
              this.Cart.Loading = false;
              this.$toast.open({
                message: "Table is not empty",
                type: "error",
                position: "bottom",
              });
            });
        }
      });
    },

    UpdateNotes() {
      this.$GeneralClasses.InputAlertText(this.Cart.Items.notes).then((Result) => {
        if (Result) {
          this.Cart.Loading = true;
          const Data = new FormData();
          Data.append("cart_nr", this.Cart.CartNR);
          Data.append("notes", Result);
          var Source = window.atob(this.$GeneralClasses.APIPOSSystem17());
          this.$http
            .post(this.$GeneralClasses.LiekasseDomain() + Source, Data, this.$GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT))
            .then((Result) => {
              // console.log(Result);
              this.CartInfosUpdate(Result.data.data, true);
              this.UpdateLocalStorage(false, Result.data.data.cart_nr);
            })
            .catch((Error) => {
              // console.log(Error);
              this.Cart.Loading = false;
            });
        }
      });
    },

    UpdateNotesDetailes(OrderDetailsId) {
      this.$GeneralClasses.InputAlertText(OrderDetailsId.notes).then((Result) => {
        if (Result) {
          this.Cart.Loading = true;
          const Data = new FormData();
          Data.append("order_details_id", OrderDetailsId.id);
          Data.append("cart_nr", this.Cart.CartNR);
          Data.append("notes", Result);
          var Source = window.atob(this.$GeneralClasses.APIPOSSystem24());
          this.$http
            .post(this.$GeneralClasses.LiekasseDomain() + Source, Data, this.$GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT))
            .then((Result) => {
              this.GetCart();
            })
            .catch((Error) => {
              // console.log(Error);
              this.Cart.Loading = false;
            });
        }
      });
    },

    UpdateDetailsTags(OrderDetailsId) {
      // To Do
    },

    GetTags(ProductID) {
      if (this.user.PublicSetting.Type == 2) {
        this.InstallValues.Tags.Items = [];
        const Data = new FormData();
        Data.append("product_id", ProductID);
        this.Cart.Loading = true;
        this.$http
          .post(this.$GeneralClasses.LiekasseDomain() + window.atob(this.$GeneralClasses.APIPOSSystem13()), Data, this.$GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT))
          .then((Result) => {
            this.InstallValues.Tags.Items = Result.data;
            this.Cart.Loading = false;
            // console.log(this.InstallValues.Tags.Items);
            this.InstallValues.Tags.Open = true;
          })
          .catch((Error) => {
            // console.log(Error);
            this.Cart.Loading = false;
          });
      } else {
        return;
      }
    },
    // AddTag_Order_details
    AddTag_Order_details(event, TagID) {
      const Data = new FormData();
      if (event == 1) {
        Data.append("tag_id", TagID);
        Data.append("order_details_id", this.InstallValues.Tags.OrderDetailsId);

        var Source = window.atob(this.$GeneralClasses.APIPOSSystem26());
      }

      this.$http
        .post(this.$GeneralClasses.LiekasseDomain() + Source, Data, this.$GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT))
        .then((Result) => {
          this.GetCart();
        })
        .catch((Error) => {
          console.log(Error.message);
        });
    },
    // DeleteTag_Order_details
    DeleteTag_Order_details(order_details_id, order_details_tag) {
      const Data = new FormData();
      Data.append("order_details_id", order_details_id);
      Data.append("order_details_tag", order_details_tag);

      var Source = window.atob(this.$GeneralClasses.APIPOSSystem27());

      this.$http
        .post(this.$GeneralClasses.LiekasseDomain() + Source, Data, this.$GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT))
        .then((Result) => {
          this.GetCart();
        })
        .catch((Error) => {
          console.log(Error.message);
        });
    },

    ResetFocus() {
      if (this.user.PublicSetting.Type == 0) {
        this.$refs.Barcode.focus();
        this.Products.Barcode = "";
      }
    },

    // Update Money
    UpdateGivedMoney() {
      this.Cart.Loading = true;
      const Data = new FormData();
      Data.append("order_id", this.Cart.Items.id);
      Data.append("gived_money", this.Money.Result || 0);
      var Source = window.atob(this.$GeneralClasses.APIPOSSystem7());
      this.$http
        .post(this.$GeneralClasses.LiekasseDomain() + Source, Data, this.$GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT))
        .then((Result) => {
          // console.log(Result);
          this.CartInfosUpdate(Result.data.data, true);
          this.LoadMoney();
        })
        .catch((Error) => {
          // console.log(Error);
          this.$toast.open({
            message: String("Cart Empty").toUpperCase(),
            type: "error",
            position: "bottom",
          });
          this.Cart.Loading = false;
        });
    },
    // Calculator Managment Start
    GetCalcResult(Value) {
      switch (this.InstallValues.Calc.Source) {
        case "Price":
          this.UpdateCart("Price", Value);
          break;
        case "QTY":
          this.UpdateCart("QTY", Value);
          break;
        case "Barcode":
          this.Products.Barcode = Value;
          this.AddToCartByBarcode();
          break;
        case "Money":
          this.Money.Result = Value;
          this.UpdateGivedMoney();
          break;
        default:
          break;
      }
    },
    // Calculator Managment End

    UpdateLocalStorage(Redirect, CartNr) {
      try {
        this.InstallValues.LSSettings.POSSystem.CartNR = CartNr;
        this.Cart.CartNR = CartNr;
        localStorage.setItem(this.$GeneralClasses.LSSettings(), JSON.stringify(this.InstallValues.LSSettings));
      } finally {
        if (Redirect) {
          setTimeout(function () {
            window.location.reload();
          }, 100);
        }
      }
    },
    UpdateMenuValueInLocalStorage() {
      this.InstallValues.LSSettings.POSSystem.MenuValue = this.Products.NumberOfMenus.Value;
      localStorage.setItem(this.$GeneralClasses.LSSettings(), JSON.stringify(this.InstallValues.LSSettings));
    },
    LoadMoney() {
      this.Money.Result = Number(this.Cart.Items.gived_money || 0).toFixed(2);
      this.Money.List = [
        { value: "5 Euro", amount: 5.0 },
        { value: "10 Euro", amount: 10.0 },
        { value: "20 Euro", amount: 20.0 },
        { value: "50 Euro", amount: 50.0 },
        { value: "100 Euro", amount: 100.0 },
      ];
    },
    LoadMenu() {
      this.Products.NumberOfMenus.Value = this.InstallValues.LSSettings.POSSystem.MenuValue;
      this.Products.NumberOfMenus.Items = [];
      for (var i = 1; i <= this.user.PrivateSetting.NumberOfMenus; i++) {
        this.Products.NumberOfMenus.Items.push(i);
      }
    },
    LoadNoPrint() {
      this.Cart.NoPrint = this.user.PrivateSetting.Print_check;
    },
  },
  async mounted() {
    this.InstallValues.LSSettings = JSON.parse(localStorage.getItem(this.$GeneralClasses.LSSettings()));
    this.InstallValues.LSSettings.POSSystem.UserScreenCounter = 0;
    localStorage.setItem(this.$GeneralClasses.LSSettings(), JSON.stringify(this.InstallValues.LSSettings));

    this.Products.Type = "Favorite";
    await this.GetProducts();
    await this.GetCategories();
    await this.GetCart();
    await this.LoadMoney();
    setTimeout(
      function () {
        this.LoadMenu();
        this.LoadNoPrint();
      }.bind(this),
      800
    );
  },
};
</script>
  